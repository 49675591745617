
/**
 * Mostrar una fecha en formato dd-mm-aaaa a partir de una fecha en formato ISO
 * @param {string} fechaISO fecha en formato ISO
 * @returns fecha en formato dd-mm-aaaa
 */
export function mostrarFechaLocalDeFechaISO(fechaISO) {
    if (fechaISO == "" || fechaISO == null) return "";
    var fecha = new Date(fechaISO)
    var cad = ((fecha.getDate() < 10) ? "0" + fecha.getDate() : fecha.getDate())
        + "-" + ((fecha.getMonth() + 1) < 10 ? "0" + (fecha.getMonth() + 1) : (fecha.getMonth() + 1))
        + "-" + fecha.getFullYear()

    return cad;
}


/**
 * Mostrar una hora a partir de una fecha en formato ISO
 * @param {string} fechaISO fecha en formato ISO
 * @returns hora en formato hh:mm:ss
 */
export function mostrarHoraLocalDeFechaISO(fechaISO) {
    var fecha = new Date(fechaISO)
    var cad = fecha.toLocaleTimeString();
    if (cad.length == 8) {
        return cad.substring(0, 5);
    } else if (cad.length == 7) {
        return cad.substring(0, 4);
    } else {
        return cad;
    }
}


/**
 * Formatear un número
 * @param {number} numero Número a formatear
 * @param {number} decimales Número de decimales que tendrá el número formateado
 * @param {boolean} guionCero verdadero para sustituir un cero (0) por un guión (-)
 * @returns 
 */
export function formatearNumero(numero, decimales, guionCero=false) {
    // UseGrouping: true es necesario para el español ya que los miles no los agrupa cunado el número es menor de 10.000
    // 9999,00 -> queremos 9.999,00
    // 99.999,00
    if (guionCero && numero==0) return "-";

    return numero.toLocaleString(undefined, { maximumFractionDigits: decimales, minimumFractionDigits: decimales, useGrouping: true })
}