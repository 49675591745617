import React from 'react';

export default function MiniKPI({ titulo, valor, color }) {
    if (!color) color = "bg-black"
    if (!titulo) titulo = "Título"
    if (!valor) valor = "Valor"

    return (
        <div className={"card border-0 " + color + " text-white mb-2"}>
            <div className="card-body pt-1 pb-0">
                {titulo}
                <h4>{valor}</h4>
            </div>
        </div>
    )
}