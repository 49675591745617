import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { JSONRPC } from './../../utilidades/JSONRPC';
import VARGLO from '../../comun/globales.js';
import Logotipo from '../../components/propios/logotipo.jsx';
import AvisosLegales from '../../components/propios/avisos-legales.jsx';

class Login extends React.Component {

	static contextType = AppSettings;

	constructor(props) {
		super(props);
		this.state = {
			usuario: '',
			password: '',
			error: null,
			recordar: false,
			mensajeValidacion: '',
			errorQM: null,
			redirect: false
		}

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangeUsuario = this.handleChangeUsuario.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.handleChangeRecordar = this.handleChangeRecordar.bind(this);
	}


	componentWillMount() {
		// Borrar todas las variables almacenadas en local:
		// usuario, cliente, nombre, token
		//localStorage.clear();
		if (localStorage.getItem('usuario')) {
			this.setState({ usuario: localStorage.getItem('usuario') })
		}
		if (localStorage.getItem('password')) {
			this.setState({ password: localStorage.getItem('password') })
		}
		this.setState({ recordar: (localStorage.getItem('recordar') == 'true') })
	}


	componentDidMount() {
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppContentClass('p-0');
		//this.setState({ recordar: localStorage.getItem('recordar') ? true : false })
	}


	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppContentClass('');
	}


	handleSubmit(event) {
		event.preventDefault();

		var parametros = {
			usuario: this.state.usuario,
			password: this.state.password
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('login.usuario', parametros, false))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error('Error ...');
				}
			})
			.then((rpc) => {
				if (rpc.result.validado) {
					VARGLO.usuario = this.state.usuario;
					VARGLO.nombreUsuario = rpc.result.usuario.nombre;					
					VARGLO.emailUsuario =  rpc.result.usuario.email;					
					VARGLO.idCliente = rpc.result.cliente.id;										
					VARGLO.nombreCliente = rpc.result.cliente.nombre;										
					VARGLO.token = rpc.result.token;
					VARGLO.bannerAMostrar = rpc.result.banner;
					VARGLO.ejerciciosVisibles = rpc.result.ejerciciosVisibles

					if (this.state.recordar) {
						localStorage.setItem('usuario', this.state.usuario);
						localStorage.setItem('password', this.state.password);
						localStorage.setItem('token', rpc.result.token)
					}
					localStorage.setItem('recordar', this.state.recordar)
					this.setState({ errorQM: (rpc.error) ? rpc.error : null })
					this.setState(state => ({
						redirect: true
					}));
				} else {
					this.setState({ mensajeValidacion: rpc.result.mensaje })
				}
			})
			.catch(error => this.setState({ error }));
	}


	MensajeError(men) {
		return (<div className="alert alert-danger alert-block">
			<a className="close" data-dismiss="alert" href="#">×</a>
			<h4 className="alert-heading">Error!</h4>
			{men}
		</div>);
	}


	handleChangeUsuario(event) {
		this.setState({ usuario: event.target.value });
	}

	handleChangePassword(event) {
		this.setState({ password: event.target.value });
	}

	handleChangeRecordar(event) {
		if (!event.target.checked) {
			localStorage.clear();
		}
		this.setState({ recordar: event.target.checked });
		localStorage.setItem('recordar', event.target.checked)
	}


	renderValidacion() {
		if (this.state.mensajeValidacion != "") {
			//return <div className="alert alert-danger" role="alert"><center><i class="fas fa-2x fa-circle-exclamation fa-fw"></i><br /><br /><strong>&nbsp;{this.state.mensajeValidacion}</strong></center></div>
			//return <span className="text-danger"><strong>{this.state.mensajeValidacion}</strong></span>
			return (<div class="note note-danger">
				<div class="note-icon"><i class="fas fa-circle-exclamation"></i></div>
				<div class="note-content">
					<h5><b>{this.state.mensajeValidacion}</b></h5>
				</div>
			</div>)
		}
	}
	

	render() {
		if (this.state.redirect) {
			//Navegar al raiz parece que deja iluminado siempre esta opción del menú
			//return <Navigate to='/' />;
			return <Navigate to='/inicio/inicio' />;
		}
		return (
			<div className="login login-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/salpicar.png)' }}></div>
					<div className="news-caption">
						<Logotipo alto="22" estilo="blanco" />
						<p>
							Bienvenido al portal de clientes de Saniplast Soluciones Sostenibles del Agua.
						</p>
					</div>
				</div>
				<div className="login-container">
					<div className="login-header mb-30px">
						<div className="brand">
							<div className="d-flex align-items-center">
								<Logotipo alto="40" />
							</div>
							<small>Portal de clientes de Saniplast Soluciones Sostenibles del Agua</small>
						</div>
						<div className="icon">
							<i className="fa fa-sign-in-alt"></i>
						</div>
					</div>
					<div className="login-content">
						<form onSubmit={this.handleSubmit} className="fs-13px">
							<div className="form-floating mb-15px">
								<input type="text" className="form-control h-45px fs-13px" placeholder="Nombre de usuario (ejemplo S1234.1)" id="emailAddress"
									value={this.state.usuario} onChange={this.handleChangeUsuario}
								/>
								<label htmlFor="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Nombre de usuario (ejemplo S1234.1)</label>
							</div>
							<div className="form-floating mb-15px">
								<input type="password" className="form-control h-45px fs-13px" placeholder="Contraseña" id="password"
									value={this.state.password} onChange={this.handleChangePassword}
								/>
								<label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">Contraseña</label>
							</div>
							<div className="form-check mb-30px">
								<input className="form-check-input" type="checkbox" value="1" id="rememberMe" onChange={this.handleChangeRecordar} defaultChecked={this.state.recordar} />
								<label className="form-check-label" htmlFor="rememberMe">
									Recordar
								</label>
							</div>
							<div className="mb-15px">
								<button type="submit" className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">Acceder</button>
							</div>
							{this.renderValidacion()}
							<div className="mb-40px pb-40px text-inverse">
								Si no tiene nombre de usuario, solicítelo poniéndose en contacto con la sucursal más cercana de Saniplast.
							</div>

							<hr className="bg-gray-600 opacity-2" />
							<div className="text-gray-600 text-center text-gray-500-darker mb-0">
								&copy; Saniplast Soluciones Sostenibles del agua 2023
							</div>
							<br />
							<div className="text-gray-600 text-center text-gray-500-darker mb-0">
								<AvisosLegales />
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default Login;