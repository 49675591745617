import React from 'react';
import { Navigate } from 'react-router-dom';
import { JSONRPC } from './../../utilidades/JSONRPC';
import VARGLO from '../../comun/globales.js';
import MensajeErrorHTTP from '../../components/propios/error-http.jsx';
import MensajeCargando from '../../components/propios/cargando.jsx';
import SelectorEjercicio from '../../components/propios/selector-ejercicio.jsx';
import DataTable from 'react-data-table-component';
import TablaSinDatos from '../../components/propios/tabla-sin-datos.jsx';
import MiniKPI from '../../components/propios/minikpi';
import { descargarFacturaPDF, descargarAlbaranPDF } from '../../utilidades/descargas';
import MenuRapido from '../../components/propios/menu-rapido';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import { mostrarFechaLocalDeFechaISO, formatearNumero } from '../../utilidades/Formateado';
import { mostrarNotificacion } from '../../utilidades/notificaciones';
import { AppSettings } from '../../config/app-settings.js';


class ConsultaAlbaranes extends React.Component {

	static contextType = AppSettings;

	columnas = [
		{ name: 'Sucursal', selector: row => row.nombreSucursal, sortable: true, hide: "md" },
		{ name: 'Albarán', selector: row => row.id, sortable: true },
		{
			name: 'Fecha albarán', selector: row => row.fecha, sortable: true,
			format: row => mostrarFechaLocalDeFechaISO(row.fecha)
		},
		{ name: 'Obra', selector: row => row.obra, sortable: true, hide: "md", grow: 3 },
		{ name: 'Referencia', selector: row => row.referencia, sortable: true, hide: "md", grow: 3 },
		{
			name: 'Importe', selector: row => row.importe, sortable: true, right: true,
			format: row => formatearNumero(row.importe, 2)
		},
		{ name: 'Factura', selector: row => row.factura.id, sortable: true, hide: "md" },
		{
			name: 'Fecha factura', selector: row => row.factura.fecha, sortable: true, hide: "md",
			format: row => mostrarFechaLocalDeFechaISO(row.fechaFactura)
		},
		{
			name: 'Descargar',
			cell: (props) => <button className="btn btn-primary pe-3 ps-3" onClick={() => this.descargarAlbaran(props.id, props.idSucursal, props.ejercicio)}><i className="fas fa-file-pdf"></i> PDF</button>,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		}
	];


	constructor(props) {
		super(props);
		this.state = {
			listaAlbaranes: null,
			listaAlbaranesTipo: null,
			listaFiltradaAlbaranes: null,
			ejercicio: new Date().getFullYear(),
			datosAlbaranes: null,
			isLoading: false,
			errorHTTP: null,
			filtro: "",
			tabActivo: "TODOS",
			errorQM: null
		}
	}


	/*
	Desplegable que muestra datos de los albaranes de las facturas
	*/
	ExpandedComponent = ({ data }) => this.renderDesplegableAlbaran(data)

	renderDesplegableAlbaran(data) {
		return (
			<div className='row'>
				<div className='col-lg-4'>
					<div className="card border-0 m-2">
						<div className="card-header h6 mb-0 bg-none p-3">
							<i className="fa fa-dolly fa-lg fa-fw text-dark me-1"></i><span className='text-dark'>Datos de entrega del albarán {data.id}</span>
						</div>
						<div className="card-body">
							<table className='table'>
								<tbody>
									<tr>
										<td>Forma de pago</td><td>{data.descripcionPago}</td>
									</tr>
									<tr>
										<td>Dejar en</td><td>{data.dejarEn}</td>
									</tr>
									<tr>
										<td>Dejar a</td><td>{data.dejarA}</td>
									</tr>
									<tr>
										<td>Matrícula</td><td>{data.matricula}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className='col-lg-4'>
					<div className="card border-0 m-2">
						<div className="card-header h6 mb-0 bg-none p-3">
							<i className="fa fa-cash-register fa-lg fa-fw text-dark me-1"></i><span className='text-dark'>Datos de facturación del albarán {data.id}</span>
						</div>
						<div className="card-body">
							<table className='table'>
								<tbody>
									<tr>
										<td>Forma de pago</td><td>{data.descripcionPago}</td>
									</tr>
									<tr>
										<td>Facturado</td>
										<td>{data.factura.fecha ? mostrarFechaLocalDeFechaISO(data.factura.fecha) : <b>SIN FACTURAR</b>}
										</td>
									</tr>
									<tr>
										<td>Factura</td>
										<td>{data.factura.id}&nbsp;
											{data.factura.id &&
												<button className="btn btn-primary btn-xs float-end" onClick={() => this.descargarFactura(data.factura.id, data.idSucursal, data.ejercicio)}>
													<i className="fas fa-file-pdf"></i> PDF</button>}
										</td>
									</tr>
									<tr>
										<td>Vencimiento</td>
										<td>{data.factura.vencimiento && mostrarFechaLocalDeFechaISO(data.factura.vencimiento)}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)
	}


	descargarAlbaran(albaran, sucursal, ejercicio) {
		mostrarNotificacion('info', 'Descarga', 'Descargando PDF del albarán ' + albaran + '. Espere unos segundos...', 'bottom-left')
		descargarAlbaranPDF(albaran, sucursal, ejercicio)
	}


	descargarFactura(factura, sucursal, ejercicio) {
		mostrarNotificacion('info', 'Descarga', 'Descargando PDF de la factura ' + factura + '. Espere unos segundos...', 'bottom-left')
		descargarFacturaPDF(factura, sucursal, ejercicio)
	}


	handleEjercicio = (ejercicioElegido) => {
		this.setState({ ejercicio: ejercicioElegido })
		this.consultarAlbaranes(ejercicioElegido)
	}


	handleCambiarTabPendientes = (e) => {
		e.preventDefault();
		this.filtrarAlbaranesPorTipo("PENDIENTES");
	}


	handleCambiarTabFacturadas = (e) => {
		e.preventDefault();
		this.filtrarAlbaranesPorTipo("FACTURADOS");
	}


	handleCambiarTabTodas = (e) => {
		e.preventDefault();
		this.filtrarAlbaranesPorTipo("TODOS");
	}


	handleFiltro = (e) => {
		const datosFiltrados = this.state.listaAlbaranesTipo.filter(row => {
			return row.referencia.toUpperCase().includes(e.target.value.toUpperCase()) || row.obra.toUpperCase().includes(e.target.value.toUpperCase())
		})
		this.setState({
			listaFiltradaAlbaranes: datosFiltrados,
			filtro: e.target.value
		})
	}


	/*
	Generar un fichero CSV con las Albaranes del ejercicio
	*/
	handleDescargarCSV = () => {
		if (this.state.listaAlbaranes == null) return;
		mostrarNotificacion('info', 'Descarga', 'Descargando fichero CSV...', 'bottom-left')
		var parametros = {
			cliente: VARGLO.idCliente,
			ejercicio: this.state.ejercicio
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('albaranes.listado.csv', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				var a = document.createElement("a");
				a.href = "data:text/csv;base64," + rpc.result.contenidoFichero;
				a.download = rpc.result.nombreFichero
				a.click();
			})
			.catch(err => mostrarNotificacion('danger', 'Descarga fallida', err.message, 'bottom-left'))
	}


	/*
	Generar un fichero excel con las Albaranes del ejercicio
	*/
	handleDescargarExcel = () => {
		if (this.state.listaAlbaranes == null) return;
		mostrarNotificacion('info', 'Descarga', 'Descargando fichero Excel...', 'bottom-left')
		var parametros = {
			cliente: VARGLO.idCliente,
			ejercicio: this.state.ejercicio
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('albaranes.listado.excel', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				var a = document.createElement("a");
				a.href = "data:application/octet-stream;base64," + rpc.result.contenidoFichero;
				a.download = rpc.result.nombreFichero
				a.click();
			})
			.catch(err => mostrarNotificacion('danger', 'Descarga fallida', err.message, 'bottom-left'))
	}


	handleImprimir = () => {
		mostrarNotificacion('danger', 'Imprimir', 'Sin implementar', 'bottom-left')
	}


	handleDescargarListadoPDF = () => {
		mostrarNotificacion('danger', 'Imprimir', 'Sin implementar', 'bottom-left')
	}


	componentWillMount() {

		this.consultarAlbaranes(this.state.ejercicio);
	}


	consultarAlbaranes(ejercicio) {
		this.setState({ isLoading: true });
		var parametros = {
			cliente: VARGLO.idCliente,
			ejercicio: ejercicio,
			orden: "DES"
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('albaranes.buscar', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				this.setState({ isLoading: false, datosAlbaranes: rpc.result, errorQM: (rpc.error) ? rpc.error : null })
				this.setState({
					listaAlbaranes: rpc.result.albaranes,
					listaAlbaranesTipo: rpc.result.albaranes,
					listaFiltradaAlbaranes: rpc.result.albaranes,
					filtro: ""
				})
			})
			.catch(err => this.setState({ errorHTTP: err.message, isLoading: false }));
	}


	filtrarAlbaranesPorTipo(tipo) {
		let datosFiltrados;
		switch (tipo) {
			case 'FACTURADOS':
				datosFiltrados = this.state.listaAlbaranes.filter(row => row.idFactura != "")
				break;
			case 'PENDIENTES':
				datosFiltrados = this.state.listaAlbaranes.filter(row => row.idFactura == "")
				break;
			default:
				tipo = "TODOS";
				datosFiltrados = this.state.listaAlbaranes;
		}
		this.setState({
			listaAlbaranesTipo: datosFiltrados,
			listaFiltradaAlbaranes: datosFiltrados
		})
		this.setState({
			tabActivo: tipo,
			filtro: ""
		})
	}


	render() {
		const { errorHTTP, errorQM } = this.state;

		if (VARGLO.usuario == "") {
			return <Navigate to='/usuario/login' />;
		}

		if (errorHTTP != null) {
			return <MensajeErrorHTTP mensaje={errorHTTP} />
		}

		if (errorQM != null) {
			if (errorQM.code === -32603) return <Navigate to='/usuario/caducada' />;
		} else {
			//if (datosAlbaranes == null) return <MensajeCargando />
		}

		return (
			<div>
				<MenuRapido />
				<h1 className="page-header">Albaranes</h1>
				<div className="row">
					<div className="col-lg-3 col-md-5">
						<div className='mb-2'>
							{<SelectorEjercicio onCambioEjercicio={this.handleEjercicio} />}
						</div>
					</div>
					<div className='col-lg-2 col-md-1'>&nbsp;</div>
					<div className='col-lg-7'>
						{this.renderKPI()}
					</div>
				</div>
				<ReactNotifications />
				<div className="card border-0">

					<ul className="nav nav-tabs nav-tabs-v2 px-3">
						<li className="nav-item me-2"><a href="#/" className={this.state.tabActivo == "TODOS" ? "nav-link px-2 active" : "nav-link px-2"} onClick={this.handleCambiarTabTodas}>Todos</a></li>
						<li className="nav-item me-2"><a href="#/" className={this.state.tabActivo == "PENDIENTES" ? "nav-link px-2 active" : "nav-link px-2"} onClick={this.handleCambiarTabPendientes}>Pendientes</a></li>
						<li className="nav-item me-2"><a href="#/" className={this.state.tabActivo == "FACTURADOS" ? "nav-link px-2 active" : "nav-link px-2"} onClick={this.handleCambiarTabFacturadas}>Facturados</a></li>
					</ul>

					<div className="tab-content p-3">
						<div className="tab-pane fade show active" id="allTab">
							<div className='row'>
								<div className='col-sm-6'>
									<div className="input-group">
										<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 10 }}>
											<i className="fa fa-search opacity-5"></i>
										</div>
										<input value={this.state.filtro} type="text" className="form-control px-35px bg-light" placeholder="Buscar Albaranes por referencia u obra..." onChange={this.handleFiltro} />
									</div>
								</div>

								<div className='col-sm-6'>
									<div className='float-end'>
										<button className="btn btn-primary mt-1 me-1 ps-4 pe-4" onClick={this.handleDescargarCSV}><i className="fas fa-file-csv"></i> CSV</button>
										<button className="btn btn-primary mt-1 ms-1 ps-4 pe-4" onClick={this.handleDescargarExcel}><i className="fas fa-file-excel"></i> Excel</button>
									</div>
								</div>
							</div>
							<br />
							{this.renderTablaAlbaranes()}
						</div>
					</div>
				</div>
			</div>
		)
	}


	renderKPI() {
		if (this.state.isLoading) {
			return <div ><br /><br /><br /></div>
		} else {
			return (
				<div>
					<div class="row">
						<div className='col-md-4'>
							<MiniKPI color='bg-blue'
								titulo='Total albaranes (con IVA)'
								valor={formatearNumero(this.state.datosAlbaranes.importeAlbaranes, 2) + ' €'} />
						</div>
						<div className='col-md-4'>
							<MiniKPI color='bg-green'
								titulo='Total facturado (con IVA)'
								valor={formatearNumero(this.state.datosAlbaranes.importeFacturado, 2) + ' €'} />
						</div>
						<div className='col-md-4'>
							<MiniKPI color='bg-orange'
								titulo='Total pendiente (con IVA)'
								valor={formatearNumero(this.state.datosAlbaranes.importePendiente, 2) + ' €'} />
						</div>
					</div>
				</div>
			)
		}
	}


	renderTablaAlbaranes() {
		if (this.state.isLoading) return <MensajeCargando />
		return (
			<DataTable
				title="Albaranes emitidos"
				noDataComponent={<TablaSinDatos mensaje="No se ha encontrado ningún albarán" />}
				columns={this.columnas}
				data={this.state.listaFiltradaAlbaranes}
				expandableRows
				highlightOnHover
				theme="dark"
				paginationComponentOptions={{
					rowsPerPageText: 'Albaranes por página: ',
					rangeSeparatorText: 'de',
					selectAllRowsItem: true,
					selectAllRowsItemText: 'Todos',
				}}
				customStyles={{
					noData: {
						style: {
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: '#000000',
							backgroundColor: 'transparent'
						},
					},
				}}
				striped
				expandableRowsComponent={this.ExpandedComponent}
				pagination />
		)
	}

}

export default ConsultaAlbaranes;