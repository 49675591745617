import React from 'react';
import VARGLO from '../../comun/globales';

class ClienteCabecera extends React.Component {
	render() {
		return (
			<div className="navbar-item navbar-user">				
                <span className='fs-4'>{VARGLO.nombreCliente}</span>				
			</div>
		);
	}
};

export default ClienteCabecera;