import React from 'react';

export default function MensajeCargando(props) {
    return (
        <div>
           <i class="fas fa-spinner fa-spin fa-2x"></i> <span className='lead'> {props.mensaje? props.mensaje:"Cargando"} </span>
           <br />
           <br />
           <br />
           <br />
           <br />
        </div>        
    )
}