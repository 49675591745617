import React from 'react';
import { Link } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';

// TODO: Cambiar el logotipo

class SesionCaducada extends React.Component {
	static contextType = AppSettings;

	componentDidMount() {
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppContentClass('p-0');
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppContentClass('');
	}

	render() {
		return (
			<div className="error">
				<br /><br /><br /><br />
				<center>
						<img src="/assets/img/logo/logo-saniplast.png" height="60" />&nbsp;&nbsp;
						<span className='saniplast display-6 align-bottom'>
							<span style={{ color: "#48AD45" }}>Mi</span>
							<span style={{ color: "#044C9E" }}>SANIPLAST</span>
						</span>
				</center>

				<div className="error-code"><i className="fa fa-clock"></i></div>
				<h1>La sesión ha caducado</h1>
				<div className="error-content">
					<div className="error-message"><small>Por favor, vuelva a iniciar la sesión</small></div>
					<br />
					<div>
						<Link to="/usuario/login" className="btn btn-success px-5">Iniciar conexión</Link>
					</div>
				</div>
			</div>
		)
	}
}

export default SesionCaducada;