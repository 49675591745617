import React from 'react';
import MenuRapido from '../../components/propios/menu-rapido.jsx';
import { JSONRPC } from './../../utilidades/JSONRPC';
import VARGLO from '../../comun/globales.js';

import { Navigate } from 'react-router-dom';
import MensajeErrorHTTP from '../../components/propios/error-http.jsx';
import MensajeCargando from '../../components/propios/cargando.jsx';
import 'react-notifications-component/dist/theme.css';
import TablaSinDatos from '../../components/propios/tabla-sin-datos.jsx';
import { descargarDocumentoPDF } from '../../utilidades/descargas.js';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { mostrarNotificacion } from '../../utilidades/notificaciones';


/**
 * Componente para mostrar y descargar un archivo
 * @param {object} param0 texto descriptivo del documento
 * @returns 
 */
function Documento({ id, texto }) {

	const arrayTexto = texto.split("\\n");
	
	const handleDescargarDocumento = (e)  => {
		e.preventDefault();
		mostrarNotificacion('info', 'Descarga', 'Descargando el archivo ' + id + '. Espere unos segundos...', 'bottom-left')		
		descargarDocumentoPDF(id)
	}


	return (
		<div className="col-xl-4 col-md-6">
			<div className="widget widget-stats bg-gradient-gray">
				<div className="stats-icon stats-icon-lg"><i className="fa fa-file-pdf fa-fw"></i></div>
				<div className="stats-content">
					<h2>{arrayTexto[0]}</h2>
					{arrayTexto[1] && <p className='fs-5'>{arrayTexto[1]}</p>}
					{arrayTexto[2] && <p>{arrayTexto[2]}</p>}
					{arrayTexto[3] && <p>{arrayTexto[3]}</p>}
					{arrayTexto[4] && <p>{arrayTexto[4]}</p>}
					<br />
				</div>
				<div className="stats-link">
					<a href="#" onClick={handleDescargarDocumento} className='fs-5'>Descargar documento&nbsp;&nbsp;<i className="fa fa-file-pdf"></i></a>
				</div>
			</div>
		</div>
	);
}



class Descargas extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			datosArchivos: null,
			isLoading: false,
			errorHTTP: null,
			errorQM: null
		}
	}


	componentWillMount() {
		this.setState({ isLoading: true });
		var parametros = {
			id: VARGLO.idCliente
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('documentos.buscar', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				this.setState({ isLoading: false, datosArchivos: rpc.result, errorQM: (rpc.error) ? rpc.error : null })
			})
			.catch(err => this.setState({ errorHTTP: err.message, isLoading: false }));
	}


	handleSolicitarCertificadoNominal = (e) => {
		e.preventDefault();
		mostrarNotificacion('info', 'Solicitud de certificado', 'Mensaje de solicitud de certificado enviado.', 'bottom-left')		
		
		var parametros = {
			usuario: VARGLO.usuario
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('documentos.solicitar.aeat', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				this.setState({ errorQM: (rpc.error) ? rpc.error : null })
			})
			.catch(err => this.setState({ errorHTTP: err.message }));
	}	


	render() {
		const { datosArchivos, errorHTTP, errorQM } = this.state;

		if (VARGLO.usuario == "") {
			return <Navigate to='/usuario/login' />;
		}

		if (errorHTTP != null) {
			return <MensajeErrorHTTP mensaje={errorHTTP} />
		}

		if (errorQM != null) {
			if (errorQM.code === -32603) return <Navigate to='/usuario/caducada' />;
		} else {
			if (datosArchivos == null) return <MensajeCargando />
		}

		if (datosArchivos && datosArchivos.documentos.length > 0) {
			return (
				<div>
					<MenuRapido />
					<ReactNotifications />					
					<h1 className="page-header">Documentos de interés</h1>
					<p>Ponemos a su disposición los siguientes documentos:</p>
					<div className="row">
						{datosArchivos.documentos.map((item) => <Documento texto={item.descripcion} id={item.id} />)}
					</div>
					<br />
					<p>Si lo desea, puede solicitarnos el envío del certificado AEAT a nombre de su empresa pulsando en el siguiente botón:</p>
					<button className="btn btn-primary mt-1 me-1 ps-4 pe-4" onClick={this.handleSolicitarCertificadoNominal}><i className="fas fa-file-pdf"></i> Solicitar certificado AEAT nominal</button>
					<br />
					<br />
					<br />
					<br />
					<br />
				</div>
			)
		} else {
			return (
				<div>
					<MenuRapido />
					<h1 className="page-header">Documentos de interés</h1>
					<TablaSinDatos mensaje="En estos momentos no hay ningún documento descargable." />
				</div>
			)
		}
	}
}

export default Descargas;