import React from 'react';
import VARGLO from '../../../comun/globales.js';
import { Link } from 'react-router-dom';

class DropdownProfile extends React.Component {
	render() {
		return (
			<div className="navbar-item navbar-user dropdown">
				<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
					<div class="image image-icon bg-gray-800 text-gray-600">
						<i class="fa fa-user"></i>
					</div>
					&nbsp;&nbsp;
					<span>
						<span className="d-none d-md-inline">{VARGLO.nombreUsuario}</span>
						<b className="caret"></b>
					</span>
				</a>
				<div className="dropdown-menu dropdown-menu-end me-1">
					{/* Con href se pierde todo el contexto de la aplicación (VARGLO, etc).
					<a href="/usuario/perfil" className="dropdown-item">Editar perfil</a>
					*/}					
				    <Link to="/usuario/perfil" className="dropdown-item"><i className="fa fa-user-gear"></i> Perfil</Link>
					<Link to="/info/ayuda" className="dropdown-item"><i className="fa fa-question-circle"></i> Ayuda</Link>
					<div className="dropdown-divider"></div>
					<a href="/usuario/login" className="dropdown-item"><i className="fa fa-sign-out-alt"></i> SALIR</a>
				</div>
			</div>
		);
	}
};

export default DropdownProfile;
