import { Store } from 'react-notifications-component';


/**
 * Mostrar una notificación y mantenerla 5 segundos
 * En las páginas llamadoras es necesario incluir un componente ReactNotifications y su import
 * import { ReactNotifications } from 'react-notifications-component';
 * @param {string} tipo 'info', 'danger', 'warning'
 * @param {string} titulo Título de la notificación
 * @param {string} mensaje Mensaje de la notifiación
 * @param {string} posicion Posición en la que aparece el mensaje (bottom-left, top-right)
 * @param {*} contenido 
 */
export function mostrarNotificacion(tipo, titulo, mensaje, posicion, contenido) {
    Store.addNotification({
        title: titulo,
        message: mensaje,
        type: tipo,
        insert: "top",
        container: posicion,
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
        dismissable: { click: true },
        content: contenido
    });
}