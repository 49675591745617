import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Avisos legales: Política de cookies, Aviso legal y Política de privacidad
 * @returns 
 */
export default function AvisosLegales() {

    return (
        <>
        <Link to="/info/cookies">Política de cookies</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/info/aviso">Aviso legal</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/info/privacidad">Política de privacidad</Link>
        </>
    )
}