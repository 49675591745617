import React from 'react';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { AppSettings } from './../../config/app-settings.js';
import BotonAtras from '../../components/propios/boton-atras.jsx';

class AvisoLegal extends React.Component {
    static contextType = AppSettings;

    componentDidMount() {
        this.context.handleSetAppSidebarNone(true);
    }

    componentWillUnmount() {
        this.context.handleSetAppSidebarNone(false);
    }
    

    render() {
        return (
            <div>

                <h1 className="page-header">Aviso legal</h1>

                <Panel>
                    <PanelHeader><i className="fas fa-circle-info"></i>&nbsp;&nbsp;Aviso legal</PanelHeader>
                    <PanelBody>
                        <p><strong>1.</strong> Información general</p>
                        <p>Las presentes condiciones regulan el uso permitido de la página con URL <a href="https://mysaniplast.es">https://www.mysaniplast.es</a>, que <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong>, con domicilio en Polígono Proni, Carretera Santander, km.7,4, 33199 Meres-Siero (Asturias), pone a disposición de los usuarios de Internet.</p>
                        <p>Se pone a disposición de los usuarios los siguientes medios donde podrán dirigir sus peticiones, cuestiones y quejas:</p>
                        <p><strong>Dirección postal:</strong></p>
                        <p>Polígono Proni, Carretera Santander, km.7,4, 33199 Meres-Siero (Asturias)</p>
                        <p><strong>Correo electrónico:</strong></p>
                        <p><a href="mailto:administracion@saniplast.es">administracion@saniplast.es</a></p>
                        <p><strong>2.</strong> Estas condiciones generales regulan el acceso y utilización del sitio&nbsp;</p>
                        <p><a href="https://mysaniplast.es">https://mysaniplast.es</a>, (en adelante «la Web») que <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong>, pone gratuitamente a disposición de los usuarios de Internet. El acceso al mismo implica su aceptación sin reservas. La utilización de determinados servicios ofrecidos en este sitio se regirá, además, por las condiciones particulares previstas en cada caso, las cuales se entenderán aceptadas por el mero uso de tales servicios.</p>
                        <p><strong>3.</strong> Se autoriza la visualización, impresión y descarga parcial del contenido de la Web sólo y exclusivamente si concurren las siguientes condiciones:</p>
                        <ul>
                            <li><p>Que sea compatible con los fines de la Web.</p>
                            </li>
                            <li><p>Que se realice con el exclusivo ánimo de obtener la información contenida para uso personal y privado. Se prohíbe expresamente su utilización con fines comerciales o para su distribución, comunicación pública, transformación o descompilación.</p>
                            </li>
                            <li><p>Que ninguno de los contenidos relacionados en la web, sean modificados de manera alguna.</p>
                            </li>
                            <li><p>Que ningún gráfico, icono o imagen disponible en la Web sea utilizado, copiado o distribuido separadamente del texto o resto de imágenes que lo acompañan.</p>
                            </li>
                            <li><p><strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones de la información contenida en la Web, de la configuración y presentación de ésta y de las condiciones de acceso.</p>
                            </li>
                        </ul>
                        <p><strong>4.</strong> <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> no garantiza la inexistencia de interrupciones o errores en el acceso a la Web o a su contenido, ni que éste se encuentre actualizado, llevando a cabo, siempre que no concurran causas que lo hagan imposible o de difícil ejecución, y tan pronto tenga noticia de los errores, desconexiones o falta de actualización en los contenidos, todas aquellas labores tendentes a subsanar los errores, restablecer la comunicación y actualizar los contenidos.</p>
                        <p><strong>5.</strong> Tanto el acceso a la Web como el uso no consentido que pueda efectuarse de la información contenida en la misma es de la exclusiva responsabilidad de quien lo realiza. <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> no responderá de ninguna consecuencia, daño o perjuicio que pudieran derivarse de dicho acceso o uso. Así mismo no se hace responsable de los errores de seguridad, que se puedan producir ni de los daños que puedan causarse al sistema informático del usuario (hardware y software), o a los ficheros o documentos almacenados en el mismo, como consecuencia de:</p>
                        <ul>
                            <li><p>La presencia de un virus en el ordenador del usuario que sea utilizado para la conexión a los servicios y contenidos de la Web.</p>
                            </li>
                            <li><p>Un mal funcionamiento del navegador.</p>
                            </li>
                            <li><p>Del uso de versiones no actualizadas del mismo.</p>
                            </li>
                        </ul>
                        <ol start="6">
                            <li><p><strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> no asume responsabilidad alguna derivada de los contenidos enlazados desde la Web, siempre que sean ajenos a la misma, ni garantiza la ausencia de virus u otros elementos en los mismos que puedan producir alteraciones en el sistema informático (hardware y software), en los documentos o los ficheros del usuario, excluyendo cualquier responsabilidad por los daños de cualquier clase causados al usuario por este motivo. En el caso de que cualquier usuario, cliente o un tercero, considerara que el contenido o los servicios prestados por las páginas enlazadas son ilícitos o lesionan bienes o derechos del propio usuario, de un cliente o de un tercero susceptibles de indemnización, y, en particular, consistan en:</p>
                            </li>
                        </ol>
                        <ul>
                            <li><p>Actividades o contenidos susceptibles de ser considerados delictivos conforme la normativa penal española.</p>
                            </li>
                            <li><p>Actividades o contenidos que violen derechos de propiedad intelectual o industrial.</p>
                            </li>
                            <li><p>Actividades o contenidos que pongan en peligro el orden público, la investigación penal, la seguridad pública y la defensa nacional.</p>
                            </li>
                            <li><p>Actividades o contenidos que pongan en peligro la protección de la salud pública, el respeto a la dignidad de la persona y al principio de no discriminación, y la protección de la salud y la infancia.</p>
                            </li>
                            <li><p>Deberá ponerse en contacto con la empresa a través del formulario de contacto o del correo electrónico que figura en la información de contacto.</p>
                            </li>
                        </ul>
                        <p><strong>7.</strong> <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> es titular de los derechos de propiedad intelectual referidos a sus productos y servicios, y específicamente de los relativos a la marca registrada. Respecto a las citas de productos y servicios de terceros, <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e intelectual, no implicando su sola mención o aparición en la Web la existencia de derechos o responsabilidad alguna de <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> sobre los mismos, como tampoco respaldo, patrocinio, o recomendación por parte de <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong>, a no ser que se manifieste de manera expresa.&nbsp;</p>
                        <p>Todos los derechos de propiedad intelectual de la información contenida en esta página web, son titularidad exclusiva de <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong>. correspondiéndonos el ejercicio exclusivo de los derechos de explotación de los mismos.</p>
                        <p>Por tanto, queda prohibida su reproducción, distribución, comunicación pública y transformación, total o parcial, sin la autorización expresa de <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong>. Igualmente, todos los nombres comerciales, marcas o signos distintos de cualquier clase contenidos en este web site están protegidos por ley.</p>
                        <p><strong>8.</strong> La utilización no autorizada de la información contenida en la Web, su reventa, así como la lesión de los derechos de Propiedad Intelectual o Industrial de <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> dará lugar a las responsabilidades legalmente establecidas.</p>
                        <p><strong>9.</strong> Todo enlace de terceros a la Web debe serlo a su página principal, quedando expresamente prohibidas los «links profundos», el «framing» y cualquier otro aprovechamiento de los contenidos de la Web, a favor de terceros no autorizado.</p>
                        <p><strong>10.</strong> <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> y el usuario, con renuncia expresa a cualquier otro fuero, se someten al de los juzgados y tribunales del domicilio del usuario para cualquier controversia que pudiera derivarse del acceso, o uso de la Web. En el caso de que el usuario tenga su domicilio fuera de España, <strong>Saniplast Soluciones Sostenibles del Agua S.L.U</strong> y el usuario se someten, con renuncia expresa a cualquier otro fuero, a los juzgados y tribunales de la ciudad de Pola de Siero.</p>
                    </PanelBody>
                </Panel>

                <p>
                    <BotonAtras />
                </p>
            </div>
        )
    }
}

export default AvisoLegal;