import React from 'react';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { AppSettings } from './../../config/app-settings.js';
import BotonAtras from '../../components/propios/boton-atras.jsx';

class PoliticaPrivacidad extends React.Component {
    static contextType = AppSettings;

    componentDidMount() {
        this.context.handleSetAppSidebarNone(true);
    }

    componentWillUnmount() {
        this.context.handleSetAppSidebarNone(false);
    }


    render() {
        return (
            <div>

                <h1 className="page-header">Política de privacidad</h1>

                <Panel>
                    <PanelHeader><i className="fas fa-circle-info"></i>&nbsp;&nbsp;Política de privacidad</PanelHeader>
                    <PanelBody>

                        <p>De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento europeo y del Consejo, de 27 de abril de 2016 y Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales y demás normativa vigente, le recomendamos que lea atentamente la siguiente información acerca del tratamiento de datos personales de clientes y usuarios.</p>
                        <p><strong>RESPONSABLE DEL TRATAMIENTO</strong></p>
                        <p><strong>Saniplast Soluciones Sostenibles del Agua S.L.U.</strong> C.I.F. B33660440 Polígono Proni Carretera Santander, km.7,4, 33199 Meres-Siero (Asturias)</p>
                        <p><strong>DATOS TRATADOS</strong></p>
                        <ul>
                            <li><p>Datos identificativos y de contacto de nuestros clientes, así como datos de facturación y económicos.</p>
                            </li>
                            <li><p>Datos identificativos, de contacto y económicos de nuestros proveedores.</p>
                            </li>
                            <li><p>Datos identificativos, de contacto, laborales, curriculares y económicos del personal laboral y de los solicitantes de empleo.</p>
                            </li>
                            <li><p>Datos de contacto de preclientes o solicitantes de información</p>
                            </li>
                            <li><p>Imágenes de los usuarios de nuestras oficinas.</p>
                            </li>
                        </ul>
                        <p><strong>FINALIDAD</strong></p>
                        <ul>
                            <li><p>Posibilitar la prestación del servicio solicitado por nuestros clientes y mantenimiento del mismo.</p>
                            </li>
                            <li><p>Realizar la facturación de nuestros servicios, así como la gestión de cobros y pagos, contabilidad, pago de impuestos y en general la realización de las tareas administrativas habituales en una empresa y el cumplimiento de nuestras obligaciones con las diferentes administraciones.</p>
                            </li>
                            <li><p>Gestionar la relación laboral con nuestros trabajadores.</p>
                            </li>
                            <li><p>Gestionar los procesos de selección de personal.</p>
                            </li>
                            <li><p>Prestar la información solicitada por usuarios de la web o personas que se ponen en contacto con nosotros.</p>
                            </li>
                            <li><p>Remitir información a nuestros clientes y usuarios sobre productos similares a los adquiridos por el cliente o sobre los que haya solicitado información previamente.</p>
                            </li>
                            <li><p>Garantizar la seguridad de las personas, bienes e instalaciones.</p>
                            </li>
                        </ul>
                        <p><strong>LEGITIMACIÓN</strong></p>
                        <p>Para el tratamiento de los datos personales de nuestros clientes, empleados y otros usuarios nos basamos fundamentalmente en su consentimiento al solicitarnos la prestación de un servicio o información sobre el mismo, así como la necesidad de cumplir con el contrato de prestación de servicio solicitado, el cumplimiento normal de las relaciones laborales, o la solicitud expresa de aquellas personas que quieran participar en un proceso de selección de personal.</p>
                        <p>Respecto al tratamiento derivado de la videovigilancia, la legitimación es el interés público, encontrándose el tratamiento habilitado por la Ley 5/2014, de 4 de abril, de Seguridad Privada.</p>
                        <p>En el caso del envío de comunicaciones comerciales de servicios relacionados con el contratado nos basamos en el interés legítimo.</p>
                        <p>Los datos que le solicitamos son adecuados, pertinentes y estrictamente necesarios para poder llevar a cabo los servicios anteriormente mencionados o gestionar adecuadamente las relaciones con todos nuestros usuarios.</p>
                        <p>En caso de no facilitarnos dichos datos no podríamos prestarle el servicio o información solicitada.</p>
                        <p><strong>DESTINATARIOS</strong></p>
                        <p>No cedemos datos de nuestros clientes/usuarios a ninguna otra empresa, salvo que exista una obligación legal o sea imprescindible para la prestación del servicio o la ejecución de un contrato.</p>
                        <p>Hay que tener en cuenta que, en cumplimiento de nuestras obligaciones fiscales y laborales, tenemos que ceder datos de clientes, proveedores y empleados a las distintas administraciones, como la Agencia Tributaria o Seguridad Social.</p>
                        <p>Por otra parte, para el normal funcionamiento de nuestra empresa, hay determinados tratamientos que encargamos a terceros, como, por ejemplo, la asesoría fiscal, laboral y contable, las empresas encargadas del software y del de gestión o la empresa que gestiona la página web, entre otras. En cualquier caso, somos muy cuidadosos seleccionando a nuestros proveedores y encargados del tratamiento, asegurándonos de que cumplen con la normativa en materia de protección de datos personales y formalizando el correspondiente contrato de encargo del tratamiento.</p>
                        <p>No realizamos transferencias internacionales de datos PLAZO</p>
                        <p><strong>DE CONSERVACIÓN</strong></p>
                        <p>Conservaremos sus datos personales el tiempo que dure nuestra relación contractual o durante el tiempo necesario para cumplir con nuestras obligaciones legales, así como la defensa de nuestros derechos.</p>
                        <p><strong>DERECHOS</strong></p>
                        <p>Según la normativa vigente en materia de protección de datos personales, los titulares de los datos que tratamos tienen la posibilidad de ejercitar gratuitamente los derechos de acceso, rectificación, supresión, oposición, limitación y portabilidad mediante escrito dirigido , mediante escrito que podrá ser presentado directamente en el domicilio social de la entidad o remitido por correo, incluyendo fotocopia del DNI, a la dirección Polígono Proni Carretera Santander, km.7,4, 33199 - Meres (Asturias).</p>
                        <p>Así mismo le comunicamos que le asiste el derecho, si lo considera pertinente a presentar una reclamación ante la Agencia Española de Protección de Datos u otra autoridad de control que pudiera resultar competente.</p>

                    </PanelBody>
                </Panel>

                <p>
                    <BotonAtras />
                </p>
            </div>
        )
    }
}

export default PoliticaPrivacidad;