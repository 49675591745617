/*
Variables globales usadas en toda la aplicación
*/

const VARGLO = {
    'usuario': '',
    'nombreUsuario': '',
    'emailUsuario': '',    
    'nombreCliente': '',
    'idCliente': '',
    'bannerAMostrar': '',
    'ejerciciosVisibles': 1,
    'token': ''
}

export default  VARGLO
