import React from 'react';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { JSONRPC } from './../../utilidades/JSONRPC';
import VARGLO from '../../comun/globales.js';
import { Navigate } from 'react-router-dom';
import MensajeErrorHTTP from '../../components/propios/error-http.jsx';
import MensajeCargando from '../../components/propios/cargando.jsx';
import { formatearNumero } from '../../utilidades/Formateado.js';
import MenuRapido from '../../components/propios/menu-rapido.jsx';

import { AppSettings } from '../../config/app-settings.js';

class InfoTributaria extends React.Component {

	static contextType = AppSettings;

	columnas = [
		{ name: 'Ejercicio', selector: row => row.ejercicio, sortable: true, grow: 0.3 },
		{
			name: 'Trimestre 1', selector: row => row.importeVentasT1, sortable: true, right: true,
			format: row => formatearNumero(row.importeVentasT1, 2)
		},
		{
			name: 'Trimestre 2', selector: row => row.importeVentasT2, sortable: true, right: true,
			format: row => formatearNumero(row.importeVentasT2, 2)
		},
		{
			name: 'Trimestre 3', selector: row => row.importeVentasT3, sortable: true, right: true,
			format: row => formatearNumero(row.importeVentasT3, 2)
		},
		{
			name: 'Trimestre 4', selector: row => row.importeVentasT4, sortable: true, right: true,
			format: row => formatearNumero(row.importeVentasT4, 2)
		},
		{
			name: 'Total', selector: row => row.importeVentasEjercicio, sortable: true, right: true,
			style: { 'font-weight': 'bold' },
			format: row => formatearNumero(row.importeVentasEjercicio, 2)
		}
	];


	constructor(props) {
		super(props);
		this.state = {
			datos347: null,
			isLoading: false,
			errorHTTP: null,
			errorQM: null
		}
	}


	componentWillMount() {
		this.setState({ isLoading: true });
		var parametros = {
			id: VARGLO.idCliente
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('clientes.datos.347', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				this.setState({ isLoading: false, datos347: rpc.result, errorQM: (rpc.error) ? rpc.error : null })
			})
			.catch(err => this.setState({ errorHTTP: err.message, isLoading: false }));
	}


	renderTabla() {

		const { datos347 } = this.state;

		return (
			<table className='table fs-5'>
				<thead>
					<tr>
						<th>Periodo</th><th className='text-end'>Importe</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Trimestre 1</td>
						<td className='text-end'>{formatearNumero(datos347.importeVentasT1)}</td>
					</tr>
					<tr >
						<td>Trimestre 2</td>
						<td className='text-end'>{formatearNumero(datos347.importeVentasT2)}</td>
					</tr>
					<tr>
						<td>Trimestre 3</td>
						<td className='text-end'>{formatearNumero(datos347.importeVentasT3)}</td>
					</tr>
					<tr>
						<td>Trimestre 4</td>
						<td className='text-end'>{formatearNumero(datos347.importeVentasT4)}</td>
					</tr>
					<tr>
						<td>Total</td>
						<td className='text-end fw-bold'>{formatearNumero(datos347.importeVentasEjercicio)}</td>
					</tr>
				</tbody>
			</table>
		)
	}


	render() {

		const { errorHTTP, errorQM, datos347 } = this.state;

		if (VARGLO.usuario == "") {
			return <Navigate to='/usuario/login' />;
		}

		if (errorHTTP != null) {
			return <MensajeErrorHTTP mensaje={errorHTTP} />
		}

		if (errorQM != null) {
			if (errorQM.code === -32603) return <Navigate to='/usuario/caducada' />;
		} else {
			if (datos347 == null) return <MensajeCargando />
		}

		return (
			<div>
				<MenuRapido/>
				<h1 className="page-header">Información tributaria <small>({datos347.cif})</small></h1>

				<div className="row">
					<div className="col-lg-6">
						<Panel>
							<PanelHeader>
								<i className="fa fa-circle-info"></i> Modelo 347&nbsp;&nbsp;
								<span className='badge bg-primary fs-6 ps-3 pe-3'>{datos347.cif}</span>&nbsp;&nbsp;
								<span className='badge bg-success fs-6 ps-3 pe-3'>Ejercicio {datos347.ejercicio}</span>
							</PanelHeader>
							<PanelBody>
								<p>Información por trimestres para la cumplimentación del modelo 347, relativa al CIF <b>{datos347.cif}</b> y al ejercicio <b>{datos347.ejercicio}</b>:</p>
								<p>Ventas a partir de 3.005,06 EUR.</p>
								<div className='row'>
									<div className='col-lg-8'>
										{this.renderTabla()}
									</div>
								</div>
								<br />
								<br />
								{/*
								<div className="d-flex flex-wrap">
									<button type="button" className="btn btn-primary me-1 mb-1"><i className="fas fa-file-pdf"></i>&nbsp;&nbsp;Documento PDF</button>
									<button className="btn btn-primary me-1 mb-1"><i className="fas fa-envelope"></i>&nbsp;&nbsp;Enviar email</button>
								</div>
		                        */}
							</PanelBody>
						</Panel>
					</div>
				</div>
			</div>
		)
	}
}

export default InfoTributaria;