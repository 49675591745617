import React from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { JSONRPC } from './../../utilidades/JSONRPC';
import VARGLO from '../../comun/globales.js';
import { formatearNumero } from '../../utilidades/Formateado.js';
import MensajeErrorHTTP from '../../components/propios/error-http.jsx';
import MensajeCargando from '../../components/propios/cargando.jsx';
import MenuRapido from '../../components/propios/menu-rapido.jsx';
import DataTable from 'react-data-table-component';
import TablaSinDatos from '../../components/propios/tabla-sin-datos.jsx';
import { Modal } from 'react-bootstrap';


import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';     // SI se usa. Aunque de un warning, si se quita, rompe.

import { AppSettings } from '../../config/app-settings.js';

class Inicio extends React.Component {

	static contextType = AppSettings;

	constructor(props) {
		super(props);
		this.state = {
			datosResumen: null,
			isLoading: false,
			errorHTTP: null,
			mostrarBanner: false,
			errorQM: null
		}
	}


	columnasVencimientos = [
		{ name: 'Descripción', selector: row => row.descripcion, sortable: true },
		{
			name: 'Importe', selector: row => row.id, sortable: true, right: true,
			format: row => formatearNumero(row.importe, 2, true),
			conditionalCellStyles: [
				{
					when: row => row.vencido,
					style: {
						color: '#ff0000'
					}
				}
			]
		},
		{
			name: 'Imp.Días 1-10', selector: row => row.id, sortable: true, right: true, hide: "md",
			format: row => formatearNumero(row.importe1a10, 2, true)
		},
		{
			name: 'Imp.Días 11-20', selector: row => row.id, sortable: true, right: true, hide: "md",
			format: row => formatearNumero(row.importe11a20, 2, true)
		},
		{
			name: 'Imp.Días 21-31', selector: row => row.id, sortable: true, right: true, hide: "md",
			format: row => formatearNumero(row.importe21a31, 2, true)
		}
	];


	componentWillMount() {
		this.setState({ isLoading: true });
		var parametros = {
			id: VARGLO.idCliente
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('clientes.estadisticas', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				this.setState({ isLoading: false, datosResumen: rpc.result, errorQM: (rpc.error) ? rpc.error : null })
				this.setState({ mostrarBanner: true })
			})
			.catch(err => this.setState({ errorHTTP: err.message, isLoading: false }));
	}


	handleMostrarBanner = () => {
		this.setState({ mostrarBanner: true })
	}


	handleCerrarBanner = () => {
		this.setState({ mostrarBanner: false })
	}

	renderKPI() {

		const { datosResumen } = this.state;

		return (
			<div className="row">

				<div className="col-xl-2 col-md-4">
					<div className="widget widget-stats bg-cyan">
						<div className="stats-icon stats-icon-lg"><i className="fa fa-credit-card fa-fw"></i></div>
						<div className="stats-content">
							<div className="stats-title">Compras ejercicio {datosResumen.ejercicioActual}</div>
							<div className="stats-number">
								{formatearNumero(datosResumen.importeVentasEjercicioActual, 0)}<small> €</small>
							</div>
							<div className="stats-desc">{formatearNumero(datosResumen.importeVentasEjercicioAnterior1, 0)}<small> €</small> en {datosResumen.ejercicioActual - 1}</div>
							<div className="stats-link">
								<Link to="/facturas/consulta-facturas">Ver detalle <i className="fa fa-arrow-alt-circle-right"></i></Link>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-2 col-md-4">
					<div className="widget widget-stats bg-orange">
						<div className="stats-icon stats-icon-lg"><i className="fa fa-cash-register fa-fw"></i></div>
						<div className="stats-content">
							<div className="stats-title">Pendiente de facturar</div>
							<div className="stats-number">
								{formatearNumero(datosResumen.importePendienteFacturar, 0)}<small> €</small>
							</div>
							<div className="stats-desc">Albaranes: {datosResumen.albaranesPendienteFacturar} </div>
							<div className="stats-link">
								<Link to="/albaranes/consulta-albaranes">Ver detalle <i className="fa fa-arrow-alt-circle-right"></i></Link>
							</div>
						</div>
					</div>
				</div>

				{datosResumen.vencimientoProximo.descripcion &&
					<div className="col-xl-2 col-md-4">
						<div className="widget widget-stats bg-green">
							<div className="stats-icon stats-icon-lg"><i className="fa fa-calendar-check fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">Próximo vencimiento</div>
								<div className="stats-number">
									{formatearNumero(datosResumen.vencimientoProximo.importe, 0)}<small> €</small>
								</div>
								<div className="stats-desc">{datosResumen.vencimientoProximo.descripcion}</div>
								<div className="stats-link">
									<Link to="/albaranes/consulta-facturas">Ver detalle <i className="fa fa-arrow-alt-circle-right"></i></Link>
								</div>
							</div>
						</div>
					</div>
				}

				{datosResumen.importeCarteraVencido > 0 &&
					<div className="col-xl-2 col-md-4">
						<div className="widget widget-stats bg-red-100">
							<div className="stats-icon stats-icon-lg"><i className="fa fa-briefcase fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">Vencido</div>
								<div className="stats-number">
									{formatearNumero(datosResumen.importeCarteraVencido, 0)}<small> €</small>
								</div>
								<div className="stats-desc">.</div>
								<div className="stats-link">
									<Link to="/albaranes/consulta-facturas">Ver detalle <i className="fa fa-arrow-alt-circle-right"></i></Link>
								</div>
							</div>
						</div>
					</div>
				}

			</div>
		)
	}



	renderImagenModal() {
		let banner = "";
		if (VARGLO.bannerAMostrar) {
			// Si hay banner que mostrar, inicializar la variable que lo muestra
			// y quitarlo de la variable global para que no se vuelva a mostrar hasta el próximo login.
			banner = VARGLO.bannerAMostrar;
			VARGLO.bannerAMostrar = "";
		} else {
			return "";
		}

		return (
			<div>
				<Modal centered show={this.state.mostrarBanner} onHide={this.handleCerrarBanner} aria-labelledby="contained-modal-title-sm" >

					{/*					
					<Modal.Body>
						<img src="https://images.squarespace-cdn.com/content/v1/603d47c9543f684c561ddd9e/1614796687190-FV9MSKZP1F078DZPT63Y/jong-marshes-79mNMAvSORg-unsplash.jpg" className="img-fluid" />
					</Modal.Body>
		            */}
					<Modal.Header closeButton />
					<Modal.Body>
						<div className='row'>
							<div className='col-12'>
								<img src={banner}
									className="img-fluid"
									style={{ width: "100%" }}
								/>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		)
	}


	render() {

		const { datosResumen, isLoading, errorHTTP, errorQM } = this.state;

		if (VARGLO.usuario == "") {
			return <Navigate to='/usuario/login' />;
		}

		if (errorHTTP != null) {
			return <MensajeErrorHTTP mensaje={errorHTTP} />
		}

		if (errorQM != null) {
			if (errorQM.code === -32603) return <Navigate to='/usuario/caducada' />;
		} else {
			if (datosResumen == null) return <MensajeCargando />
		}


		return (
			<div>

				<MenuRapido />
				<h1 className="page-header">Situación <small>Resumen</small></h1>

				{this.renderImagenModal()}


				{this.renderKPI()}

				<br />

				<div className="row">
					<div className="col-xl-5 col-md-6">
						<Panel>
							<PanelHeader><i className="fas fa-euro-sign"></i>&nbsp;&nbsp;Ventas por ejercicios y meses</PanelHeader>
							<PanelBody  className="h-600px">
								<div style={{ height: '400px' }}>
									<Bar data={datosResumen.graficoVentasTotal.data} options={datosResumen.graficoVentasTotal.options} />
								</div>
							</PanelBody>
						</Panel>
					</div>

					<div className="col-xl-5 col-md-6">
						{datosResumen.vencimientosCartera.length > 0 && this.renderResumenVencimientos()}
					</div>
				</div>
				{/*<Link to="/inicio/inicio" data-bs-toggle="modal" data-bs-target="bannerModal">...</Link>*/}
				<br /><br />
			</div>
		)
	}


	renderResumenVencimientos() {
		const { datosResumen } = this.state;

		return (
			<Panel>
				<PanelHeader><i className="fas fa-calendar-check"></i>&nbsp;&nbsp;Resumen de vencimientos</PanelHeader>
				<PanelBody className="h-600px">
					<div style={{ width: '300px' }}>
						<Bar data={datosResumen.graficoVencimientos.data} options={datosResumen.graficoVencimientos.options} />
					</div>
					<br />
					<div className='row'>
						<div className='col-lg-12'>
							<span className='fs-5'><i className="fa fa-briefcase fa-fw"></i> Detalle de vencimientos</span>
							<DataTable
								noDataComponent={<TablaSinDatos mensaje="No se ha encontrado datos de vencimientos" />}
								columns={this.columnasVencimientos}
								data={datosResumen.vencimientosCartera}
								theme='dark'
								highlightOnHover
								customStyles={{
									noData: {
										style: {
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											color: '#000000',
											backgroundColor: 'transparent'
										},
									},
								}}
							/>
						</div>
					</div>
					<br />
					{this.renderResumenCartera()}					

				</PanelBody>
			</Panel>
		)
	}


	renderResumenCartera() {
		const { datosResumen } = this.state;

		return (
			<div className='row'>
				<div className='col-lg-12'>
					<div className="widget-list rounded-bottom p-0 m-0">
						<div className="widget-list-item rounded-0">
							<div className="widget-list-content">
								<div className="widget-list-title">En bancos/cobradores (no abogados)</div>
							</div>
							<div className="widget-list-action text-nowrap">
								<b>{formatearNumero(datosResumen.importeBancosCobradores, 2)}</b>
							</div>
						</div>
						<div className="widget-list-item rounded-0 pt-3px">
							<div className="widget-list-content">
								<div className="widget-list-title">En cartera pendiente de giro o cobro</div>
							</div>
							<div className="widget-list-action text-nowrap">
								<b>{formatearNumero(datosResumen.importeCarteraPendienteGiroCobro, 2)}</b>
							</div>
						</div>
						<div className="widget-list-item rounded-0 pt-3px">
							<div className="widget-list-content">
								<div className="widget-list-title">En cartera (devoluciones)</div>
							</div>
							<div className="widget-list-action text-nowrap">
								{formatearNumero(datosResumen.importeCarteraDevoluciones, 2)}
							</div>
						</div>
						<div className="widget-list-item">
							<div className="widget-list-content">
								<div className="widget-list-title">En cartera (vencidos)</div>
							</div>
							<div className="widget-list-action text-nowrap">
								<b>{formatearNumero(datosResumen.importeCarteraVencido, 2)}</b>
							</div>
						</div>
						<div className="widget-list-item pb-3px rounded-bottom">
							<div className="widget-list-content">
								<div className="widget-list-title">En Cartera (vencido "bloqueos")</div>
							</div>
							<div className="widget-list-action text-nowrap">
								<b>{formatearNumero(datosResumen.importeCarteraVencidoBloqueos, 2)}</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		)

	}



}

export default Inicio;