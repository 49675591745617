const Menu = [
  /* Por alguna razón este path para inicio deja siempre iluinado esta opción del menú
  { path: '/', icon: 'fa fa-home', title: 'Inicio' },
  */
  { path: '/inicio/inicio', icon: 'fa fa-home', title: 'Inicio' },
  { path: '/facturas/consulta-facturas', icon: 'fa fa-euro-sign', title: 'Facturas' },
  { path: '/albaranes/consulta-albaranes', icon: 'fa fa-dolly', title: 'Albaranes' },
  /*{ path: '/saldo/saldo-cliente', icon: 'fa fa-credit-card', title: 'Saldo' },*/
  { path: '/info-tributaria/info-tributaria', icon: 'fa fa-bank', title: 'Modelo 347'},
  { path: '/descargas/descargas', icon: 'fa fa-circle-info', title: 'Documentos de interés' },
  { path: '/usuario/contacto', icon: 'fa fa-envelope', title: 'Contacto' }
  /*
  { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
    children: [
      { path: '/menu/menu-1-1', title: 'Menu 1.1',
        children: [
          { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
            children: [
              { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
              { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
            ]
          },
          { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
          { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
        ]
      },
      { path: '/menu/menu-1-2', title: 'Menu 1.2' },
      { path: '/menu/menu-1-3', title: 'Menu 1.3' },
      { path: '/info-tributaria/info-tributaria', icon: 'fa fa-bank', title: 'Modelo 347', badge:"2022" },      
    ]
  }
  */
]

export default Menu;