import React from 'react';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { AppSettings } from './../../config/app-settings.js';
import BotonAtras from '../../components/propios/boton-atras.jsx';

class PoliticaCookies extends React.Component {
    static contextType = AppSettings;

    componentDidMount() {
        this.context.handleSetAppSidebarNone(true);
    }

    componentWillUnmount() {
        this.context.handleSetAppSidebarNone(false);
    }


    render() {
        return (
            <div>

                <h1 className="page-header">Política de cookies</h1>

                <Panel>
                    <PanelHeader><i className="fas fa-circle-info"></i>&nbsp;&nbsp;Política de cookies</PanelHeader>
                    <PanelBody>

                        <p>En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico le informamos de que este sitio web, al igual que la mayoría de los sitios en Internet, usa Cookies para mejorar y optimizar la experiencia del usuario. A continuación, encontrará información detallada sobre qué son las “Cookies”, qué tipología utiliza este sitio web, cómo cambiar la configuración de sus cookies, y qué ocurre si deshabilita las Cookies.</p>
                        <p><strong>¿Qué son las cookies?</strong></p>
                        <p>Cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. El navegador del usuario memoriza cookies en el disco duro solamente durante la sesión actual ocupando un espacio de memoria mínimo y no perjudicando al ordenador. Las cookies no contienen ninguna clase de información personal específica, y la mayoría de las mismas se borran del disco duro al finalizar la sesión de navegador (las denominadas cookies de sesión).</p>
                        <p>La mayoría de los navegadores aceptan como estándar a las cookies y, con independencia de las mismas, permiten o impiden en los ajustes de seguridad las cookies temporales o memorizadas.</p>
                        <p><strong>¿Qué tipos de cookies utilizamos?</strong></p>
                        <p><strong>Cookies técnicas:</strong> Son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.</p>
                        <p><strong>Cookies de análisis:</strong> Son aquellas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</p>
                        <p><strong>Cookies de terceros:</strong> Son cookies utilizadas y gestionadas por entidades externas. Los principales objetivos para los que se utilizan cookies de terceros son la obtención de estadísticas de accesos y analizar la información de la navegación, es decir, cómo interactúa el Usuario con el Sitio Web.</p>
                        <p>La información que se obtiene se refiere, por ejemplo, al número de páginas visitadas, el idioma, el lugar a la que la dirección IP desde el que accede el Usuario, el número de Usuarios que acceden, la frecuencia y reincidencia de las visitas, el tiempo de visita, el navegador que usan, el operador o tipo de dispositivo desde el que se realiza la visita. Esta información se utiliza para mejorar el Sitio Web, y detectar nuevas necesidades para ofrecer a los Usuarios un Contenido y/o servicio de óptima calidad. En todo caso, la información se recopila de forma anónima y se elaboran informes de tendencias del Sitio Web sin identificar a usuarios individuales.</p>
                        <p>Las entidades encargadas del suministro de cookies podrán ceder esta información a terceros, siempre y cuando lo exija la ley o sea un tercero el que procese esta información para dichas entidades.</p>
                        <p><strong>El Usuario acepta expresamente, por la utilización de este sitio, el tratamiento de la información recabada en la forma y con los fines anteriormente mencionados.</strong>&nbsp;Y asimismo reconoce conocer la posibilidad de rechazar el tratamiento de tales datos o información rechazando el uso de Cookies mediante la selección de la configuración apropiada a tal fin en su navegador. Si bien esta opción de bloqueo de Cookies en su navegador puede no permitirle el uso pleno de todas las funcionalidades del sitio web.</p>
                        <p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:</p>
                        <ul>
                            <li><p><strong>Chrome:</strong>&nbsp;<a href="http://support.google.com/chrome/answer/95647?hl=es">support.google.com/chrome/answer/95647?hl=es</a></p>
                            </li>
                            <li><p><strong>Firefox:</strong>&nbsp;<a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></p>
                            </li>
                            <li><p><strong>Safari:</strong>&nbsp;<a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES&amp;locale=es_ES">support.apple.com/kb/HT1677?viewlocale=es_ES&amp;locale=es_ES</a></p>
                            </li>
                            <li><p><strong>Explorer:</strong>&nbsp;<a href="http://windows.microsoft.com/es-ES/windows-vista/Block-or-allow-cookies">windows.microsoft.com/es-ES/windows-vista/Block-or-allow-cookies</a></p>
                            </li>
                        </ul>
                        <p>Si tiene dudas sobre esta política de cookies, puede contactar con&nbsp;<strong>Saniplast Soluciones Sostenibles del Agua&nbsp;S.L.U.</strong> en&nbsp;<a href="mailto:administracion@saniplast.es">administracion@saniplast.es</a></p>

                    </PanelBody>
                </Panel>

                <p>
                    <BotonAtras />
                </p>
            </div>
        )
    }
}

export default PoliticaCookies;