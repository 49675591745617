import React from 'react';

export default function MensajeErrorQM(props) {
    return (
        <div class="alert alert-danger fade show">
            <h4><i className="fa fa-circle-exclamation"></i>&nbsp;&nbsp;Error</h4>
            <b>{props.mensaje}</b>
            <button type="button" class="btn-close" data-bs-dismiss="alert" />
        </div>
    )
}