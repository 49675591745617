import React from 'react';
import App from './../app.jsx';
import { Outlet } from 'react-router-dom';

import Inicio from "./../pages/inicio/inicio.js";
import Login from '../pages/usuario/login.js';
import Contacto from '../pages/usuario/contacto.js';
import EditarPerfil from '../pages/usuario/editar-perfil.js';
import SesionCaducada from '../pages/usuario/sesion-caducada.js';
import ConsultaFacturas from '../pages/facturas/consulta-facturas.js';
import ConsultaAlbaranes from '../pages/albaranes/consulta-albaranes.js';
import SaldoCliente from '../pages/saldo/saldo-cliente.js';
import InfoTributaria from '../pages/info-tributaria/info-tributaria.js';
import Descargas from '../pages/descargas/descargas.js';
import AvisoLegal from '../pages/info/aviso-legal.js';
import PoliticaCookies from '../pages/info/politica-cookies.js';
import PoliticaPrivacidad from '../pages/info/politica-privacidad.js';
import Ayuda from '../pages/info/ayuda.js';

/* 
Si dejamos sólo la ruta "" para "<Inicio />" el menú "Inicio" siempre estará iluminado.
Por eso añadiomos el path "inicio/*" y lo usamos en el fichero de configuración del menú.
*/

const AppRoute = [
  {
    path: '*',
    element: <App />,
    children: [

      {
        path: '',
        element: <Inicio />
      },
      {
        path: 'inicio/*',
        element: <Outlet />,
        children: [
          { path: 'inicio', element: <Inicio /> }
        ]
      },

      {
        path: 'facturas/*',
        element: <Outlet />,
        children: [
          { path: 'consulta-facturas', element: <ConsultaFacturas /> }
        ]
      },
      {
        path: 'albaranes/*',
        element: <Outlet />,
        children: [
          { path: 'consulta-albaranes', element: <ConsultaAlbaranes /> }
        ]
      },
      {
        path: 'saldo/*',
        element: <Outlet />,
        children: [
          { path: 'saldo-cliente', element: <SaldoCliente /> }
        ]
      },
      {
        path: 'info-tributaria/*',
        element: <Outlet />,
        children: [
          { path: 'info-tributaria', element: <InfoTributaria /> }
        ]
      },
      {
        path: 'descargas/*',
        element: <Outlet />,
        children: [
          { path: 'descargas', element: <Descargas /> }
        ]
      },
      {
        path: 'info/*',
        element: <Outlet />,
        children: [
          { path: 'ayuda', element: <Ayuda /> },
          { path: 'aviso', element: <AvisoLegal /> },
          { path: 'cookies', element: <PoliticaCookies /> },
          { path: 'privacidad', element: <PoliticaPrivacidad /> }
        ]
      },      
      {
        path: 'usuario/*',
        element: <Outlet />,
        children: [
          { path: 'login', element: <Login /> },
          { path: 'contacto', element: <Contacto /> },
          { path: 'perfil', element: <EditarPerfil />},
          { path: 'caducada', element: <SesionCaducada /> }
        ]
      }
    ]
  }
];

export default AppRoute;