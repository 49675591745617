import React from 'react';
import MenuRapido from '../../components/propios/menu-rapido';

import './contacto.css'

class Contacto extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			text: ''
		}
	}


	handleChange = (value) => {
		this.setState({ text: value })
	}


	render() {
		this.map = {
			center: {
				lat: 40,
				lng: -4
			},
			zoom: 6
		}

		return (
			<div>
				<MenuRapido/>
				<h1 className="page-header">Contacto<small></small></h1>
				<p className='fs-6'>Puede dejarnos cualquier comentario en <b>administracion@saniplast.es</b>, o ponerse en contacto con cualquiera de nuestros centros:</p>
				<p className='fs-6'>Puede averiguar el teléfono y la dirección de nuestros centros pulsando sobre cualquier icono de la pantalla.</p>

				<div className='row'>
					<div className='col-lg-10'>
					<div className="map-responsive">
							<iframe src="https://www.google.com/maps/d/embed?mid=1PZCPdjo51cwKovX1CT-LiWKB0IeWpbI&ehbc=2E312F&z=6"
								className="d-block"
								frameBorder="0"
								title="Sucursales de Saniplast"
								allowFullScreen=""
								frameborder="0"
								style={{ border: 0 }}>
							</iframe>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Contacto;