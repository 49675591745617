/*
  Deshabilitado por petición en la reunión del 19.05.22
*/
import React from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { JSONRPC } from './../../utilidades/JSONRPC';
import VARGLO from '../../comun/globales.js';
import DataTable from 'react-data-table-component';
import TablaSinDatos from '../../components/propios/tabla-sin-datos.jsx';
import { Navigate } from 'react-router-dom';
import MensajeErrorHTTP from '../../components/propios/error-http.jsx';
import MensajeCargando from '../../components/propios/cargando.jsx';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import { mostrarFechaLocalDeFechaISO, formatearNumero } from '../../utilidades/Formateado';
import { mostrarNotificacion } from '../../utilidades/notificaciones';
import { AppSettings } from '../../config/app-settings.js';
import { Bar } from 'react-chartjs-2';

//const rowDisabledCriteria = row => row.isOutOfStock;
//const rowPreDisabled = row => row.disabled;
const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;



class SaldoCliente extends React.Component {

	static contextType = AppSettings;

	columnasAlbaranes = [
		{ name: 'Sucursal', selector: row => row.nombreSucursal, sortable: true, hide: "md" },
		{ name: 'Albarán', selector: row => row.id, sortable: true },
		{
			name: 'Fecha albarán', selector: row => row.fecha, sortable: true,
			format: row => mostrarFechaLocalDeFechaISO(row.fecha)
		},
		{ name: 'Referencia', selector: row => row.referencia, sortable: true, hide: "md", grow: 4 },
		{
			name: 'Importe', selector: row => row.importe, sortable: true, right: true,
			format: row => formatearNumero(row.importe, 2)
		},
		{
			name: 'Descargar',
			cell: (props) => <button className="btn btn-primary" onClick={() => this.descargarAlbaran(props)} >PDF</button>,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		}
	];

	columnasVencimientos = [
		{ name: 'Descripción', selector: row => row.descripcion, sortable: true },
		{
			name: 'Importe', selector: row => row.id, sortable: true, right: true,
			format: row => formatearNumero(row.importe, 2, true),
			conditionalCellStyles: [
				{
					when: row => row.vencido,
					style: {
						color: '#ff0000'
					}
				}
			]
		},
		{
			name: 'Imp.Días 1-10', selector: row => row.id, sortable: true, right: true, hide: "md",
			format: row => formatearNumero(row.importe1a10, 2, true)
		},
		{
			name: 'Imp.Días 11-20', selector: row => row.id, sortable: true, right: true, hide: "md",
			format: row => formatearNumero(row.importe11a20, 2, true)
		},
		{
			name: 'Imp.Días 21-31', selector: row => row.id, sortable: true, right: true, hide: "md",
			format: row => formatearNumero(row.importe21a31, 2, true)
		}
	];


	constructor(props) {
		super(props);
		this.state = {
			datosSaldo: null,
			isLoading: false,
			errorHTTP: null,
			errorQM: null
		}
	}


	componentWillMount() {
		this.setState({ isLoading: true });
		var parametros = {
			id: VARGLO.idCliente
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('clientes.saldo', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				this.setState({ isLoading: false, datosSaldo: rpc.result, errorQM: (rpc.error) ? rpc.error : null })
			})
			.catch(err => this.setState({ errorHTTP: err.message, isLoading: false }));
	}


	descargarAlbaran(props) {
		mostrarNotificacion('info', 'Descarga', 'Descargando albarán en PDF. Espere unos segundos...', 'bottom-left')
		var parametros = {
			id: props.id,
			ejercicio: props.ejercicio,
			sucursal: props.idSucursal
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('albaranes.pdf', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				// Descarga de un fichero
				var a = document.createElement("a")
				a.href = "data:application/pdf;base64," + rpc.result.contenidoFichero;
				a.download = rpc.result.nombreFichero
				a.click();
			})
			.catch(err => console.log(err.message));
	}


	render() {

		const { errorHTTP, errorQM, datosSaldo } = this.state;

		if (VARGLO.usuario == "") {
			return <Navigate to='/usuario/login' />;
		}

		if (errorHTTP != null) {
			return <MensajeErrorHTTP mensaje={errorHTTP} />
		}

		if (errorQM != null) {
			if (errorQM.code === -32603) return <Navigate to='/usuario/caducada' />;
		} else {
			if (datosSaldo == null) return <MensajeCargando />
		}

		return (
			<div>
				<ol className="breadcrumb float-xl-end">
					<li className="breadcrumb-item"><Link to="/page-option/blank">Home</Link></li>
					<li className="breadcrumb-item"><Link to="/page-option/blank">Page Options</Link></li>
					<li className="breadcrumb-item active">Blank Page</li>
				</ol>
				<h1 className="page-header">Saldo del cliente <small>subtítulo</small></h1>

				{this.renderKPI()}

				<ReactNotifications />

				{this.renderResumenVencimientos()}

				{this.renderAlbaranesPendientes()}

				{this.renderResumenSaldo()}

			</div>
		)
	}


	renderKPI() {
		const { datosSaldo } = this.state;
		return (
			<div className="row">
				<div className="col-xl-2 col-md-6">
					<div className="widget widget-stats bg-green">
						<div className="stats-icon stats-icon-lg"><i className="fa fa-credit-card fa-fw"></i></div>
						<div className="stats-content">
							<div className="stats-title">Límite asegurado</div>
							<div className="stats-number">
								{formatearNumero(datosSaldo.limiteCYC, 0)}<small> €</small>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-md-6">
					<div className="widget widget-stats bg-teal">
						<div className="stats-icon stats-icon-lg"><i className="fa fa-cash-register fa-fw"></i></div>
						<div className="stats-content">
							<div className="stats-title">Pendiente de facturar</div>
							<div className="stats-number">
								{formatearNumero(datosSaldo.importePendienteFacturar, 0)}<small> €</small>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-md-6">
					<div className="widget widget-stats bg-purple">
						<div className="stats-icon stats-icon-lg"><i className="fa fa-briefcase fa-fw"></i></div>
						<div className="stats-content">
							<div className="stats-title">Efectos en cartera</div>
							<div className="stats-number">
								{formatearNumero(datosSaldo.importeCarteraPendienteGiroCobro, 0)}<small> €</small>
							</div>
						</div>
					</div>
				</div>

				{datosSaldo.importeCarteraVencido > 0 &&
					<div className="col-xl-2 col-md-6">
						<div className="widget widget-stats bg-red-400">
							<div className="stats-icon stats-icon-lg"><i className="fa fa-triangle-exclamation fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">Efectos en cartera vencidos</div>
								<div className="stats-number">
									{formatearNumero(datosSaldo.importeCarteraVencido, 0)}<small> €</small>
								</div>
							</div>
						</div>
					</div>
				}

				<div className="col-xl-2 col-md-6">
					<div className="widget widget-stats bg-blue">
						<div className="stats-icon stats-icon-lg"><i className="fa fa-bank fa-fw"></i></div>
						<div className="stats-content">
							<div className="stats-title">Efectos en el banco</div>
							<div className="stats-number">
								{formatearNumero(datosSaldo.importeBancosCobradores, 0)}<small> €</small>
							</div>
						</div>
					</div>
				</div>

				{datosSaldo.importeBancosVencido > 0 &&
					<div className="col-xl-2 col-md-6">
						<div className="widget widget-stats bg-orange">
							<div className="stats-icon stats-icon-lg"><i className="fa fa-triangle-exclamation fa-fw"></i></div>
							<div className="stats-content">
								<div className="stats-title">Efectos en banco vencidos</div>
								<div className="stats-number">
									{formatearNumero(datosSaldo.importeBancosVencido, 0)}<small> €</small>
								</div>
							</div>
						</div>
					</div>
				}

			</div>
		)
	}


	renderResumenVencimientos() {
		const { datosSaldo } = this.state;
		return (
			<Panel>
				<PanelHeader>Resumen de vencimientos</PanelHeader>
				<PanelBody>
					<div className='row'>
						<div className='col-lg-8'>
							<span className='fs-4'><i className="fa fa-briefcase fa-fw"></i> Efectos en cartera</span>
							<DataTable
								noDataComponent={<TablaSinDatos mensaje="No se ha encontrado datos de vencimientos" />}
								columns={this.columnasVencimientos}
								data={datosSaldo.vencimientosCartera}
								theme='dark'
								highlightOnHover
								customStyles={{
									noData: {
										style: {
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											color: '#000000',
											backgroundColor: 'transparent'
										},
									},
								}}
							/>
							<br />
							<span className='fs-4'><i className="fa fa-bank fa-fw"></i> Efectos en el banco</span>
							<DataTable
								noDataComponent={<TablaSinDatos mensaje="No se ha encontrado datos de vencimientos" />}
								columns={this.columnasVencimientos}
								data={datosSaldo.vencimientosBanco}
								theme='dark'
								highlightOnHover
								customStyles={{
									noData: {
										style: {
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											color: '#000000',
											backgroundColor: 'transparent'
										},
									},
								}}
							/>

						</div>
						
						<div className='col-lg-4'>
							<br />
							<div style={{ width: '300px' }}>
								<Bar data={datosSaldo.graficoVencimientos.data} options={datosSaldo.graficoVencimientos.options} />
							</div>
						</div>
					</div>

				</PanelBody>
			</Panel>
		)
	}


	renderResumenSaldo() {
		const { datosSaldo } = this.state;
		return (
			<Panel>
				<PanelHeader>Resumen de saldo</PanelHeader>
				<PanelBody>
					<div className='row'>
						<div className='col-lg-5'>
							<div className="widget-list rounded-bottom">
								<div className="widget-list-item  rounded-0">
									<div className="widget-list-content">
										<div className="widget-list-title">En bancos/cobradores (no abogados)</div>
									</div>
									<div className="widget-list-action text-nowrap">
										<b>{formatearNumero(datosSaldo.importeBancosCobradores, 2)}</b>
									</div>
								</div>
								<div className="widget-list-item rounded-0 pt-3px">
									<div className="widget-list-content">
										<div className="widget-list-title">En cartera pendiente de giro o cobro</div>
									</div>
									<div className="widget-list-action text-nowrap">
										<b>{formatearNumero(datosSaldo.importeCarteraPendienteGiroCobro, 2)}</b>
									</div>
								</div>
								<div className="widget-list-item rounded-0 pt-3px">
									<div className="widget-list-content">
										<div className="widget-list-title">En cartera (devoluciones)</div>
									</div>
									<div className="widget-list-action text-nowrap">
										{formatearNumero(datosSaldo.importeCarteraDevoluciones, 2)}
									</div>
								</div>
								<div className="widget-list-item">
									<div className="widget-list-content">
										<div className="widget-list-title">En cartera (vencidos)</div>
									</div>
									<div className="widget-list-action text-nowrap">
										<b>{formatearNumero(datosSaldo.importeCarteraVencido, 2)}</b>
									</div>
								</div>
								<div className="widget-list-item pb-3px rounded-bottom">
									<div className="widget-list-content">
										<div className="widget-list-title">En Cartera (vencido "bloqueos")</div>
									</div>
									<div className="widget-list-action text-nowrap">
										<b>{formatearNumero(datosSaldo.importeCarteraVencidoBloqueos, 2)}</b>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-5'>
							<div className="widget-list rounded-bottom">
								<div className="widget-list-item">
									<div className="widget-list-content">
										<div className="widget-list-title">En el banco al descuento ENVIADO</div>
									</div>
									<div className="widget-list-action text-nowrap">
										<b>{formatearNumero(datosSaldo.importeBancoDescuentoEnviado, 2)}</b>
									</div>
								</div>
								<div className="widget-list-item rounded-0 pt-3px">
									<div className="widget-list-content">
										<div className="widget-list-title">Banco al descuento COBRADO</div>
									</div>
									<div className="widget-list-action text-nowrap">
										<b>{formatearNumero(datosSaldo.importeBancoDescuentoCobrado, 2)}</b>
									</div>
								</div>
								<div className="widget-list-item rounded-0 pt-3px">
									<div className="widget-list-content">
										<div className="widget-list-title">&nbsp;</div>
									</div>
									<div className="widget-list-action text-nowrap">
										&nbsp;
									</div>
								</div>
								<div className="widget-list-item">
									<div className="widget-list-content">
										<div className="widget-list-title">Albaranes pendientes de facturar</div>
									</div>
									<div className="widget-list-action text-nowrap">
										<b>{formatearNumero(datosSaldo.importePendienteFacturar, 2)}</b>
									</div>
								</div>
								<div className="widget-list-item pb-3px rounded-bottom">
									<div className="widget-list-content">
										<div className="widget-list-title">Pedidos programados a corto plazo</div>
									</div>
									<div className="widget-list-action text-nowrap">
										<b>{formatearNumero(datosSaldo.importePedidosProgramados, 2)}</b>
									</div>
								</div>
							</div>
						</div>
					</div>
				</PanelBody>
			</Panel>
		)
	}


	renderAlbaranesPendientes() {
		const { datosSaldo } = this.state;
		return (
			<Panel>
				<PanelHeader>Albaranes pendientes de facturar</PanelHeader>
				<PanelBody>
					<DataTable
						title="Albaranes pendientes de facturar"
						noDataComponent={<TablaSinDatos mensaje="No se ha encontrado ningún albarán" />}
						columns={this.columnasAlbaranes}
						data={datosSaldo.albaranes}
						expandableRows
						highlightOnHover
						theme="dark"
						paginationComponentOptions={{
							rowsPerPageText: 'Albaranes por página: ',
							rangeSeparatorText: 'de',
							selectAllRowsItem: true,
							selectAllRowsItemText: 'Todos',
						}}
						customStyles={{
							noData: {
								style: {
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#000000',
									backgroundColor: 'transparent'
								},
							},
						}}
						striped
						expandableRowsComponent={ExpandedComponent}
						pagination />
				</PanelBody>
			</Panel>
		)
	}







}

export default SaldoCliente;