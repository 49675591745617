import React from 'react';

export default function MensajeErrorHTTP(props) {
    return (
        <div class="note note-danger">
            <div class="note-icon"><i class="fas fa-circle-exclamation"></i></div>
            <div class="note-content">
                <h5><b>{props.mensaje}</b></h5>
            </div>
        </div>
    )
}