import React from 'react';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { AppSettings } from './../../config/app-settings.js';
import BotonAtras from '../../components/propios/boton-atras.jsx';

class Ayuda extends React.Component {
    static contextType = AppSettings;

    componentDidMount() {
        this.context.handleSetAppSidebarNone(true);
    }

    componentWillUnmount() {
        this.context.handleSetAppSidebarNone(false);
    }


    render() {
        return (
            <div>

                <h1 className="page-header">Ayuda</h1>

                <Panel>
                    <PanelHeader><i className="fas fa-circle-info"></i>&nbsp;&nbsp;Ayuda</PanelHeader>
                    <PanelBody>

                        <p>MySaniplast es un portal para los clientes de Saniplast que permite realizar de forma sencilla operaciones como la descarga de albaranes, facturas o conocer las cantidades pendientes por facturas.</p>
                        <p>La aplicación consta de varios módulos que detallaremos a continuación</p>
                        <h2 class="atx" id="login">Login</h2>
                        <p>Para acceder a MySaniplast es necesario contar con un nombre de usuario que tiene que ser solicitado a cualquier centro de Saniplast, o bien a la dirección <a href="mailto:administracion@saniplast.es">administracion@saniplast.es</a>. Conocido el nombre de usuario y la contraseña se introducirán en la pantalla de inicio para acceder al sitio.</p>
                        <p>En cualquier momento se puede modificar la contraseña y el email de contacto del usuario haciendo clic sobre el nombre de usuario y eligiendo la opción "Perfil".</p>
                        <h2 class="atx" id="inicio">Inicio</h2>
                        <p>La pantalla de inicio muestra un resumen de la situación del cliente. Se muestra un gráfico de ventas por meses y centros, un resumen de ventas del ejercicio, el total pendiente de facturar, etc. Sirve también como puerta de entrada al resto de módulos.</p>
                        <p>Todos los módulos son accesibles desde el menú de navegación situado a la izquierda de la pantalla, o en un menú desplegable en el caso de dispositivos móviles.</p>
                        <h2 class="atx" id="facturas">Facturas</h2>
                        <p>En esté módulo se muestran todas las facturas de un determinado ejercicio que es seleccionable utilizando un desplegable en la parte superior de la pantalla. La aplicación muestra el total facturado, cobrado y pendiente del ejercicio seleccionado.</p>
                        <p>Las facturas mostradas se pueden filtrar por su situación de pago (Pendientes o pagadas). También se pueden filtrar por referencia u obra introduciendo un texto en el campo "Buscar facturas por referencia u obra..."</p>
                        <p>Se puede obtener un listado "CSV" o Excel pulsando los botones "CSV" y "Excel" de la parte superior derecha de la pantalla.</p>
                        <p>También se puede descargar una factura en concreto pulsando sobre el botón "PDF" de la columna "Descargar".</p>
                        <p>Haciendo clic en el botón "&gt;" el sistema mostrará información de los albaranes que componen la factura, pudiendo descargar el PDF de cada uno de ellos. También se mostrará la fecha del vencimiento de la factura.</p>
                        <h2 class="atx" id="albaranes">Albaranes</h2>
                        <p>En el módulo de albaranes se muestran todos los albaranes de un determinado ejercicio que es seleccionable utilizando un desplegable en la parte superior de la pantalla. La aplicación muestra el total servido, el total facturado y el total pendiente de facturar del ejercicio seleccionado.</p>
                        <p>Los albaranes mostrados se pueden filtrar por su estado de facturación (facturadas o no). También se pueden filtrar por referencia u obra introduciendo un texto en el campo "Buscar facturas por referencia u obra..."</p>
                        <p>Se puede obtener un listado "CSV" o Excel pulsando los botones "CSV" y "Excel" de la parte superior derecha de la pantalla.</p>
                        <p>También se puede descargar un albarán en concreto pulsando sobre el botón "PDF" de la columna "Descargar".</p>
                        <p>Haciendo clic en el botón "&gt;" el sistema mostrará información de los datos de entrega del albarán, forma de pago, matrícula, etc, así como de los datos de facturación. También es posible descargar la factura en la que está incluido el albarán.</p>
                        <h2 class="atx" id="modelo-347">Modelo 347</h2>
                        <p>En esta opción del menú se muestra la información por trimestres para la cumplimentación del modelo 347. Siempre se muestra la información del ejercicio anterior.</p>
                        <h2 class="atx" id="documentos-de-interes">Documentos de interés</h2>
                        <p>En este módulo se muestran diferentes certificados de Saniplast que pueden ser de interés para el cliente:</p>
                        <ul>
                            <li><p>Certificado que acredita estar al corriente en el cumplimiento de las obligaciones tributarias.</p>
                            </li>
                            <li><p>Certificado que acredita estar al corriente en las obligaciones de la Seguridad Social.</p>
                            </li>
                        </ul>
                        <h2 class="atx" id="contacto">Contacto</h2>
                        <p>En esta página mostramos un mapa interactivo indicando todos los centros de Saniplast, así como sus direcciones y teléfonos de contacto.</p>

                    </PanelBody>
                </Panel>

                <p>
                    <BotonAtras />
                </p>
            </div>
        )
    }
}

export default Ayuda;