import VARGLO from "../comun/globales";


/**
 * @author Marcos Alonso Vega - INGESCO 30.05.23
 * @param {string} metodo Nombre del método JSON RPC
 * @param {*} parametros Objeto con los parámetros a pasar al procedimiento
 * @param {boolean} enviarToken true para enviar el token "Bearer" de autorización
 * @returns 
 */
export function JSONRPC(metodo, parametros = null, enviarToken = true) {
    // Formación de la cabecera de la petición

    var myHeaders = new Headers()
    if (enviarToken == true) {
        myHeaders.append("Authorization", "Bearer " + VARGLO.token);
    }
    myHeaders.append("Content-Type", "application/json");

    var raw;

    if (parametros == null) {
        raw = JSON.stringify({
            "jsonrpc": "2.0",
            "id": "SWSP",
            "method": metodo
        });
    } else {
        raw = JSON.stringify({
            "jsonrpc": "2.0",
            "id": "SWSP",
            "method": metodo,
            "params": parametros
        });
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return requestOptions;
}

