/*
Componente React para elegir un ejercicio
MAV 09.05.2023
*/

import React from 'react';
import { useState } from "react";
import Select from 'react-select';
import VARGLO from '../../comun/globales.js';

export default function SelectorEjercicio({ onCambioEjercicio }) {
    let opciones = []
    const actual = new Date().getFullYear()
    const ejercicios = VARGLO.ejerciciosVisibles;

    for (let i = 0; i < ejercicios; i++) {
        opciones.push({
            value: actual - i,
            label: 'Ejercicio ' + (actual - i).toString()
        })
    }

    const [selected, setSelected] = useState(opciones[0]);

    /*
const opciones = [
    { value: '2023', label: 'Ejercicio 2023' },
    { value: '2022', label: 'Ejercicio 2022' },
    { value: '2021', label: 'Ejercicio 2021' }
];
*/

    const handleCambioEjercicio = (ejercicioSeleccionado) => {
        setSelected(ejercicioSeleccionado);
        onCambioEjercicio(ejercicioSeleccionado.value);
    };

    return (
        <Select
            menuPortalTarget={document.body}
            styles={{
                // Corrige el problema de "overlap" del componente        
                menuPortal: base => ({ ...base, zIndex: 9999 })
            }}
            onChange={handleCambioEjercicio}
            options={opciones}
            placeholder="Elija une ejercicio..."
            value={selected}
        />
    )
}