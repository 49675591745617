import React from 'react';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import MenuRapido from '../../components/propios/menu-rapido.jsx';
import { JSONRPC } from './../../utilidades/JSONRPC';
import VARGLO from '../../comun/globales.js';
import { AppSettings } from './../../config/app-settings.js';

import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { mostrarNotificacion } from '../../utilidades/notificaciones';

import { Navigate } from 'react-router-dom';
import MensajeErrorHTTP from '../../components/propios/error-http.jsx';
import MensajeErrorQM from '../../components/propios/error-qm.jsx';
import Alerta from '../../components/propios/alerta.jsx';

/**
 * Comprueba si un email es válido
 * @param {string} email Email a validar
 * @returns {boolean} Cierto si el email es válido
 */
function esEmailValido(email) {
	return /\S+@\S+\.\S+/.test(email);
}

class EditarPerfil extends React.Component {

	static contextType = AppSettings;

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			emailUsuario: VARGLO.emailUsuario,
			passwordActual: "",
			passwordNueva: "",
			passwordConfirmada: "",
			mensajePassword: "",
			mensajeEmail: "",
			pedirLogin: false,
			errorQM: null,
		}
	}



	modificarMensajePassword(mensaje) {
		this.setState({ mensajePassword: mensaje })
	}


	/**
	 * Botón de cambio de password
	 * @param {*} event 
	 * @returns 
	 */
	handleCambiarPassword = (event) => {
		event.preventDefault();

		if (this.state.passwordNueva !== this.state.passwordConfirmada) {
			this.modificarMensajePassword("Las contraseñas no coinciden.")
			this.setState({ passwordNueva: "", passwordConfirmada: "" })
			return
		}

		var parametros = {
			usuario: VARGLO.usuario,
			passwordActual: this.state.passwordActual,
			passwordNueva: this.state.passwordNueva
		}

		fetch(process.env.REACT_APP_WS, JSONRPC('usuarios.cambiar.password', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				if (!rpc.error) {
					this.setState({ isLoading: false, errorQM: null })
					if (rpc.result.password.modificado) {
						this.modificarMensajePassword('')
						document.getElementById('botonCerrar').click()
						mostrarNotificacion('info', 'Contraseña modificada', "La contraseña ha sido modificada", 'bottom-left')
					} else {
						this.modificarMensajePassword(rpc.result.password.mensaje)
					}

				} else {
					this.setState({ errorQM: rpc.error })
				}
			})
			.catch(error => this.setState({ error }));
	}


	/**
	 * Manejador para el botón que cambia los datos del perfil
	 * @param {*} event 
	 */
	handleCambiarDatosPerfil = (event) => {
		event.preventDefault();

		var parametros = {
			usuario: VARGLO.usuario,
			email: this.state.emailUsuario
		}

		fetch(process.env.REACT_APP_WS, JSONRPC('usuarios.cambiar.datos', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				if (!rpc.error) {
					this.setState({ isLoading: false, emailUsuario: rpc.result.usuario.email, errorQM: null })
					mostrarNotificacion('info', 'Actualizar datos', "Datos del perfil actualizados", 'bottom-left')
					VARGLO.emailUsuario = rpc.result.usuario.email
				} else {
					this.setState({ errorQM: rpc.error })
				}
			})
			.catch(error => this.setState({ error }));
	}


	/**
	 * Manejador genérico para cambios en controles "Input".
	 * Tiene que coincidir el nombre del "Input" con una variable de state.
	 * @param {*} event 
	 */
	handleCambiarInput = (event) => {
		event.preventDefault();
		const target = event.target;
		this.setState({
			[target.name]: target.value,
		});
	}


	/**
	 * Manejador para el cambio en el texto box del email
	 * @param {*} event 
	 */
	handleCambiarEmail = (event) => {
		event.preventDefault();
		this.setState({ emailUsuario: event.target.value })
		if (esEmailValido(event.target.value)) {
			this.setState({ mensajeEmail: "" })
		} else {
			this.setState({ mensajeEmail: "Introduzca un email correcto" })
		}
	}


	
	render() {
		const { errorHTTP, errorQM } = this.state;

		if (this.state.pedirLogin) {
			mostrarNotificacion('info', 'Contraseña modificada', 'La contraseña ha sido modificada.', 'bottom-left')
			return <Navigate to='/usuario/login' />;
		}

		if (VARGLO.usuario == "") {
			return <Navigate to='/usuario/login' />;
		}

		if (errorHTTP != null) {
			return <MensajeErrorHTTP mensaje={errorHTTP} />
		}

		if (errorQM != null) {
			if (errorQM.code === -32603) return <Navigate to='/usuario/caducada' />;
		} else {
			//if (datosFacturas == null) return <MensajeCargando />
		}

		return (
			<div>
				{errorQM && <MensajeErrorQM mensaje={errorQM.message} />}
				<MenuRapido />
				<ReactNotifications />
				<h1 className="page-header">Datos del usuario</h1>
				<div className="row mb-3">
					<div className="col-xl-6">
						<Panel>
							<PanelHeader><i className="fa fa-user-gear"></i>&nbsp;&nbsp;Datos del usuario</PanelHeader>
							<PanelBody>
								<p>
									<br />Desde este formulario puede modificar el email de contacto.
									<br />También puede modificar su contraseña pulsando sobre el botón "Cambiar..."
									<br />Para que los cambios sean efectivos no olvide pulsar sobre "Actualizar y Cerrar"
									<br />
									<br />
								</p>

								<fieldset>
									<div className="row mb-15px">
										<label className="form-label col-form-label col-md-3">Usuario</label>
										<div className="col-md-9">
											<input type="text" className="form-control mb-5px bg-gray-100 fw-bold" placeholder="Nombre del usuario"
												value={VARGLO.usuario.toUpperCase()} readOnly />
										</div>
									</div>
									<div className="row mb-15px">
										<label className="form-label col-form-label col-md-3">Nombre</label>
										<div className="col-md-9">
											<input type="text" className="form-control mb-5px  bg-gray-100" placeholder="Nombre del usuario"
												value={VARGLO.nombreUsuario} readOnly />
										</div>
									</div>
									<div className="row mb-15px">
										<label className="form-label col-form-label col-md-3">Contraseña</label>
										<div className="col-md-9">
											<div className='input-group'>
												<input name="password" value="1234" type="password" className="form-control mb-5px bg-gray-100 fw-bold" readOnly />
												<button data-bs-toggle="modal" data-bs-target="#modalAlert" className="btn btn-primary mb-5px">Cambiar...</button>
											</div>
										</div>
									</div>
									<div className="row mb-15px">
										<label className="form-label col-form-label col-md-3">Email</label>
										<div className="col-md-9">
											<input type="email" className="form-control mb-5px" placeholder="Introduzca un email"
												name="emailUsuario" value={this.state.emailUsuario} onChange={this.handleCambiarEmail} />
											<span className='text-red'>{this.state.mensajeEmail}</span>
										</div>
									</div>
									<div className="row">
										<div className="col-md-9 offset-md-3 mt-5">
											<button onClick={this.handleCambiarDatosPerfil} type="submit" className="btn btn-primary me-5px">Actualizar y cerrar</button>
										</div>
									</div>
								</fieldset>
							</PanelBody>
						</Panel>
					</div>
				</div>


				<div className="modal fade" id="modalAlert">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title"><i className="fa fa-user-lock"></i>&nbsp;&nbsp;Cambiar contraseña</h4>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
							</div>
							<div className="modal-body">
								<span className='form-label'>Introduzca una contraseña con al menos 6 caracteres:</span>
								<br />
								<br />
								<br />
								<form onSubmit={this.handleCambiarPassword} className="fs-13px">
									<fieldset>
										<div className="row mb-15px">
											<label className="form-label col-form-label col-md-6">Contraseña actual</label>
											<div className="col-md-6">
												<input name="passwordActual" value={this.state.passwordActual} onChange={this.handleCambiarInput}
													type="password" className="form-control mb-5px text-gray-500-darker" placeholder="Contraseña actual"
												/>
											</div>
										</div>
										<div className="row mb-15px">
											<label className="form-label col-form-label col-md-6">Nueva contraseña</label>
											<div className="col-md-6">
												<input name="passwordNueva" value={this.state.passwordNueva} onChange={this.handleCambiarInput}
													type="password" className="form-control mb-5px" placeholder="Nueva contraseña" />
											</div>
										</div>

										<div className="row mb-15px">
											<label className="form-label col-form-label col-md-6">Vuelva a escribir la contraseña</label>
											<div className="col-md-6">
												<input name="passwordConfirmada" value={this.state.passwordConfirmada} onChange={this.handleCambiarInput}
													type="password" className="form-control mb-5px" placeholder="Nueva contraseña" />
											</div>
										</div>
									</fieldset>
								</form>
								<Alerta mensaje={this.state.mensajePassword} />
							</div>
							<div className="modal-footer">
								<button id="botonCerrar" className="btn btn-default w-100px me-5px" data-bs-dismiss="modal">Cancelar</button>
								<button type="submit" className="btn btn-primary w-100px me-5px" onClick={this.handleCambiarPassword}>Cambiar</button>
							</div>
						</div>
					</div>
				</div>


			</div>

		)
	}
}

export default EditarPerfil;