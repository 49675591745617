import React from 'react';
import { Link } from 'react-router-dom';

export default function MenuRapido() {
    return (
        <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
        <li className="breadcrumb-item"><Link to="/facturas/consulta-facturas">Facturas</Link></li>
        <li className="breadcrumb-item"><Link to="/albaranes/consulta-albaranes">Albaranes</Link></li>        
        {/*<li className="breadcrumb-item active">Blank Page</li>*/}
    </ol>
    )
}