import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Botón que implementa el ir a la página anterior
 * @param {string} texto a mostrar en el botón ("Atrás" por defecto")
 * @returns 
 */
export default function BotonAtras({ texto }) {
    if (!texto) texto = "Atrás"
    const navigate = useNavigate();

    return (
        <button className="btn btn-primary" onClick={() => navigate(-1)} >
            <i className="fa fa-arrow-circle-left"></i> {texto}
            </button>
    )
}