import React from 'react';
//import logoBlanco from "assets/img/logo/logo-saniplast-blanco.png"
//import logoColor from "assets/img/logo/logo-saniplast.png"

export default function Logotipo({ alto, estilo = "color" }) {

    const imagen =  (estilo == "blanco") ? "/assets/img/logo/logo-saniplast-blanco.png" : "/assets/img/logo/logo-saniplast.png"
    //const imagen = process.env.PUBLIC_URL + ((estilo == "blanco") ? "/assets/img/logo/logo-saniplast-blanco.png" : "/assets/img/logo/logo-saniplast.png")
    //const imagen = (estilo == "blanco" ? logoBlanco : logoColor)

    if (estilo == "blanco") {
        return (
            <>
                {
                    alto ? <img src={imagen} height={alto} /> : <img src={imagen} />
                }
                &nbsp;&nbsp;
                <span className='saniplast'>MySANIPLAST</span>
            </>
        )
    } else {
        return (
            <>
                {
                    alto ? <img src={imagen} height={alto} /> : <img src={imagen} />
                }
                &nbsp;&nbsp;
                <span className='saniplast'>
                    <span style={{ color: "#48AD45" }}>My</span>
                    <span style={{ color: "#044C9E" }}>SANIPLAST</span>
                </span>
            </>
        )
    }


}