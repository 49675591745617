import React from 'react';
import { Navigate } from 'react-router-dom';
import { JSONRPC } from './../../utilidades/JSONRPC';
import VARGLO from '../../comun/globales.js';
import { descargarFacturaPDF, descargarAlbaranPDF } from '../../utilidades/descargas';
import MensajeErrorHTTP from '../../components/propios/error-http.jsx';
import MensajeCargando from '../../components/propios/cargando.jsx';
import SelectorEjercicio from '../../components/propios/selector-ejercicio.jsx';
import DataTable from 'react-data-table-component';
import TablaSinDatos from '../../components/propios/tabla-sin-datos.jsx';
import MiniKPI from '../../components/propios/minikpi';
import MenuRapido from '../../components/propios/menu-rapido';

import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { mostrarNotificacion } from '../../utilidades/notificaciones';

import { mostrarFechaLocalDeFechaISO, formatearNumero } from '../../utilidades/Formateado';
import { AppSettings } from '../../config/app-settings.js';



class ConsultaFacturas extends React.Component {

	static contextType = AppSettings;

	columnas = [
		{ name: 'Sucursal', selector: row => row.nombreSucursal, sortable: true, hide: "md" },
		{ name: 'Factura', selector: row => row.id, sortable: true },
		{
			name: 'Fecha', selector: row => row.fecha, sortable: true,
			format: row => mostrarFechaLocalDeFechaISO(row.fecha)
		},
		{
			name: 'Vencimiento', selector: row => row.vencimientos[0] ? row.vencimientos[0].fecha : "", sortable: true,
			format: row => mostrarFechaLocalDeFechaISO(row.vencimientos[0] ? row.vencimientos[0].fecha : "")
		},
		{
			name: 'Importe', selector: row => row.importe, sortable: true, right: true,
			format: row => formatearNumero(row.importe, 2)
		},
		{ name: 'Albaranes', selector: row => row.albaranes.length, right: true, sortable: true, grow: 0.5 },
		{ name: 'Obra', selector: row => row.obra, sortable: true, hide: "md", grow: 3 },
		{ name: 'Referencia', selector: row => row.referencia, sortable: true, hide: "md", grow: 2 },
		{ name: 'Pago', selector: row => row.formaPago, sortable: true, hide: "md" },
		{
			name: 'Situacion', selector: row => row.situacion, sortable: true, hide: "md",
			format: row => (row.situacion == "COBRADO") ? "COBRADO" : "PENDIENTE"
		},
		{
			name: 'Descargar',
			cell: (props) => <button className="btn btn-primary pe-3 ps-3" onClick={() => this.descargarFactura(props.id, props.idSucursal, props.ejercicio)} ><i className="fas fa-file-pdf"></i> PDF</button>,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		}
	];


	constructor(props) {
		super(props);
		this.state = {
			listaFacturas: null,
			listaFacturasTipo: null,
			listaFiltradaFacturas: null,
			ejercicio: new Date().getFullYear(),
			datosFacturas: null,
			isLoading: false,
			errorHTTP: null,
			filtro: "",
			tabActivo: "TODAS",
			errorQM: null
		}
	}


	descargarAlbaran(albaran, sucursal, ejercicio) {
		mostrarNotificacion('info', 'Descarga', 'Descargando PDF del albarán ' + albaran + '. Espere unos segundos...', 'bottom-left')
		descargarAlbaranPDF(albaran, sucursal, ejercicio)
	}


	descargarFactura(factura, sucursal, ejercicio) {
		mostrarNotificacion('info', 'Descarga', 'Descargando PDF de la factura ' + factura + '. Espere unos segundos...', 'bottom-left')
		descargarFacturaPDF(factura, sucursal, ejercicio)
	}


	/*
	Desplegable que muestra datos de los albaranes de las facturas
	*/
	ExpandedComponent = ({ data }) => this.renderDesplegableFactura(data)

	renderDesplegableFactura(data) {
		return (
			<div className='row'>
				<div className='col-lg-4'>
					<div className="card border-0 m-2">
						<div className="card-header h6 mb-0 bg-none p-3">
							<i className="fa fa-dolly fa-lg fa-fw text-dark me-1"></i><span className='text-dark'>Albaranes de la factura {data.id}</span>
							&nbsp;&nbsp;<span className="badge bg-success float-end fs-5">{(data.albaranes != null) ? data.albaranes.length : 0}</span>
						</div>
						<div className="card-body">
							<table className='table'>
								<thead>
									<tr>
										<th>Albarán</th><th>Fecha</th><th className='text-end'>Importe</th><th className='text-end'>Descargar</th>
									</tr>
								</thead>
								<tbody>
									{(data.albaranes != null) ? (
										data.albaranes.map((item) =>
											<tr>
												<td>{item.id}</td>
												<td>{mostrarFechaLocalDeFechaISO(item.fecha)}</td>
												<td className="text-end">{formatearNumero(item.importe, 2)}</td>
												<td className="text-end">
													<button className="btn btn-primary btn-xs" onClick={() => this.descargarAlbaran(item.id, data.idSucursal, data.ejercicio)}>
														<i className="fas fa-file-pdf"></i> PDF</button>
												</td>
											</tr>
										)) : (
										<tr><td>No hay</td></tr>
									)}
									<tr><td>Total</td><td></td><td className="text-end fw-bold">{formatearNumero(data.importe, 2)}</td></tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className='col-lg-4'>
					<div className="card border-0 m-2">
						<div className="card-header h6 mb-0 bg-none p-3">
							<i className="fa fa-calendar-check fa-lg fa-fw text-dark me-1"></i><span className='text-dark'>Vencimientos de la factura {data.id}</span>
							&nbsp;&nbsp;<span className="badge bg-success float-end fs-5">{(data.vencimientos != null) ? data.vencimientos.length : 0}</span>
						</div>
						<div className="card-body">
							<table className='table'>
								<thead>
									<tr>
										<th>Fecha</th><th className='text-end'>Importe</th>
									</tr>
								</thead>
								<tbody>
									{(data.vencimientos != null && data.vencimientos.length > 0) ? (
										data.vencimientos.map((item) =>
											<tr>
												<td>{mostrarFechaLocalDeFechaISO(item.fecha)}</td>
												<td className="text-end">{formatearNumero(item.importe, 2)}</td>
											</tr>
										)) : (
										<tr><td>No hay vencimientos</td></tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)
	}


	/*
	Manejador de eventos del desplegable de ejercicios
	*/
	handleEjercicio = (ejercicioElegido) => {
		this.setState({ ejercicio: ejercicioElegido })
		this.consultarFacturas(ejercicioElegido)
	}


	/*
	Manejador del filtro de búsqueda
	*/
	handleFiltro = (e) => {
		const datosFiltrados = this.state.listaFacturasTipo.filter(row => {
			return row.referencia.toUpperCase().includes(e.target.value.toUpperCase()) || row.obra.toUpperCase().includes(e.target.value.toUpperCase())
		})
		this.setState({
			listaFiltradaFacturas: datosFiltrados,
			filtro: e.target.value
		})
	}


	handleCambiarTabPendientes = (e) => {
		e.preventDefault();
		this.filtrarFacturasPorTipo("PENDIENTES");
	}


	handleCambiarTabPagadas = (e) => {
		e.preventDefault();
		this.filtrarFacturasPorTipo("PAGADAS");
	}


	handleCambiarTabTodas = (e) => {
		e.preventDefault();
		this.filtrarFacturasPorTipo("TODAS");
	}


	/*
	Generar un fichero CSV con las facturas del ejercicio
	*/
	handleDescargarCSV = () => {
		if (this.state.listaFacturas == null) return;
		mostrarNotificacion('info', 'Descarga', 'Descargando fichero CSV...', 'bottom-left')
		var parametros = {
			cliente: VARGLO.idCliente,
			ejercicio: this.state.ejercicio
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('facturas.listado.csv', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				var a = document.createElement("a");
				a.href = "data:text/csv;base64," + rpc.result.contenidoFichero;
				a.download = rpc.result.nombreFichero
				a.click();
			})
			.catch(err => mostrarNotificacion('danger', 'Descarga fallida', err.message, 'bottom-left'))
	}


	/*
	Generar un fichero excel con las facturas del ejercicio
	*/
	handleDescargarExcel = () => {
		if (this.state.listaFacturas == null) return;
		mostrarNotificacion('info', 'Descarga', 'Descargando fichero Excel...', 'bottom-left')
		var parametros = {
			cliente: VARGLO.idCliente,
			ejercicio: this.state.ejercicio
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('facturas.listado.excel', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				var a = document.createElement("a");
				a.href = "data:application/octet-stream;base64," + rpc.result.contenidoFichero;
				a.download = rpc.result.nombreFichero
				a.click();
			})
			.catch(err => mostrarNotificacion('danger', 'Descarga fallida', err.message, 'bottom-left'))
	}


	componentWillMount() {

		this.consultarFacturas(this.state.ejercicio);
	}


	consultarFacturas(ejercicio) {
		this.setState({ isLoading: true });
		var parametros = {
			cliente: VARGLO.idCliente,
			ejercicio: ejercicio,
			orden: "DES"
		}
		fetch(process.env.REACT_APP_WS, JSONRPC('facturas.buscar', parametros))
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(response.status + ' ' + response.statusText);
				}
			})
			.then((rpc) => {
				this.setState({ isLoading: false, datosFacturas: rpc.result, errorQM: (rpc.error) ? rpc.error : null })
				this.setState({
					listaFacturas: rpc.result.facturas,
					listaFacturasTipo: rpc.result.facturas,
					listaFiltradaFacturas: rpc.result.facturas,
					filtro: ""
				})
			})
			.catch(err => this.setState({ errorHTTP: err.message, isLoading: false }));
	}


	filtrarFacturasPorTipo(tipo) {
		let datosFiltrados;
		switch (tipo) {
			case 'PAGADAS':
				datosFiltrados = this.state.listaFacturas.filter(row => row.situacion.toUpperCase() == "COBRADO")
				break;
			case 'PENDIENTES':
				datosFiltrados = this.state.listaFacturas.filter(row => row.situacion.toUpperCase() != "COBRADO")
				break;
			default:
				tipo = "TODAS";
				datosFiltrados = this.state.listaFacturas;
		}
		this.setState({
			listaFacturasTipo: datosFiltrados,
			listaFiltradaFacturas: datosFiltrados
		})
		this.setState({
			tabActivo: tipo,
			filtro: ""
		})
	}


	render() {
		const { datosFacturas, isLoading, errorHTTP, errorQM, datosFiltradosFacturas } = this.state;

		if (VARGLO.usuario == "") {
			return <Navigate to='/usuario/login' />;
		}

		if (errorHTTP != null) {
			return <MensajeErrorHTTP mensaje={errorHTTP} />
		}

		if (errorQM != null) {
			if (errorQM.code === -32603) return <Navigate to='/usuario/caducada' />;
		} else {
			//if (datosFacturas == null) return <MensajeCargando />
		}

		return (
			<div>
				<MenuRapido />
				<h1 className="page-header">Facturas</h1>
				<div className="row">
					<div className="col-lg-3 col-md-5">
						<div className='mb-2'>
							{<SelectorEjercicio onCambioEjercicio={this.handleEjercicio} />}
						</div>
					</div>
					<div className='col-lg-2 col-md-1'>&nbsp;</div>
					<div className='col-lg-7'>
						{this.renderKPI()}
					</div>
				</div>
				<ReactNotifications />
				<div className="card border-0">

					<ul className="nav nav-tabs nav-tabs-v2 px-3">
						<li className="nav-item me-2"><a href="#/" className={this.state.tabActivo == "TODAS" ? "nav-link px-2 active" : "nav-link px-2"} onClick={this.handleCambiarTabTodas}>Todas</a></li>
						<li className="nav-item me-2"><a href="#/" className={this.state.tabActivo == "PAGADAS" ? "nav-link px-2 active" : "nav-link px-2"} onClick={this.handleCambiarTabPagadas}>Pagadas</a></li>
						<li className="nav-item me-2"><a href="#/" className={this.state.tabActivo == "PENDIENTES" ? "nav-link px-2 active" : "nav-link px-2"} onClick={this.handleCambiarTabPendientes}>Pendientes</a></li>
					</ul>

					<div className="tab-content p-3">
						<div className="tab-pane fade show active" id="allTab">
							<div className='row'>
								<div className='col-sm-6'>
									<div className="input-group">
										<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 10 }}>
											<i className="fa fa-search opacity-5"></i>
										</div>
										<input value={this.state.filtro} type="text" className="form-control px-35px bg-light" placeholder="Buscar facturas por referencia u obra..." onChange={this.handleFiltro} />
									</div>
								</div>
								<div className='col-sm-6'>
									<div className='float-end'>
										<button className="btn btn-primary mt-1 me-1 ps-4 pe-4" onClick={this.handleDescargarCSV}><i className="fas fa-file-csv"></i> CSV</button>
										<button className="btn btn-primary mt-1 ms-1 ps-4 pe-4" onClick={this.handleDescargarExcel}><i className="fas fa-file-excel"></i> Excel</button>
									</div>
								</div>
							</div>
							<br />
							{this.renderTablaFacturas()}
						</div>
					</div>
				</div>
			</div>
		)
	}


	renderKPI() {
		if (this.state.isLoading) {
			return <div ><br /><br /><br /></div>
		} else {
			return (
				<div>
					<div class="row">
						<div className='col-md-4'>
							<MiniKPI color='bg-blue'
								titulo='Total facturado (con IVA)'
								valor={formatearNumero(this.state.datosFacturas.importeFacturas, 2) + ' €'} />
						</div>
						<div className='col-md-4'>
							<MiniKPI color='bg-green'
								titulo='Total cobrado (con IVA)'
								valor={formatearNumero(this.state.datosFacturas.importeCobrado, 2) + ' €'} />
						</div>
						<div className='col-md-4'>
							<MiniKPI color='bg-orange'
								titulo='Total pendiente (con IVA)'
								valor={formatearNumero(this.state.datosFacturas.importePendiente, 2) + ' €'} />
						</div>
					</div>
				</div>
			)
		}
	}


	renderTablaFacturas() {
		if (this.state.isLoading) return <MensajeCargando />
		return (
			<DataTable
				title="Facturas  emitidas"
				noDataComponent={<TablaSinDatos mensaje="No se ha encontrado ninguna factura" />}
				columns={this.columnas}
				data={this.state.listaFiltradaFacturas}
				expandableRows
				highlightOnHover
				theme="dark"
				paginationComponentOptions={{
					rowsPerPageText: 'Facturas por página: ',
					rangeSeparatorText: 'de',
					selectAllRowsItem: true,
					selectAllRowsItemText: 'Todos',
				}}
				customStyles={{
					noData: {
						style: {
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: '#000000',
							backgroundColor: 'transparent'
						},
					},
				}}
				striped
				expandableRowsComponent={this.ExpandedComponent}
				pagination />
		)
	}
}

export default ConsultaFacturas;