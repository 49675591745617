import { JSONRPC } from "./JSONRPC";


/**
 * Descargar documento o certificado
 * @param {string} albaran Número de albarán
 * @param {string} sucursal Código de sucursal
 * @param {number} ejercicio Ejercicio o año
 */
 export function descargarDocumentoPDF(idDocumento) {
    var parametros = {
        id: idDocumento,
    }
    fetch(process.env.REACT_APP_WS, JSONRPC('documentos.pdf', parametros))
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.status + ' ' + response.statusText);
            }
        })
        .then((rpc) => {
            // Descarga de un fichero
            var a = document.createElement("a")
            a.href = "data:application/pdf;base64," + rpc.result.contenidoFichero;
            a.download = rpc.result.nombreFichero
            a.click();
        })
        .catch(err => console.log(err.message));
}


/**
 * Descargar un albarán en PDF
 * @param {string} albaran Número de albarán
 * @param {string} sucursal Código de sucursal
 * @param {number} ejercicio Ejercicio o año
 */
export function descargarAlbaranPDF(albaran, sucursal, ejercicio) {
    var parametros = {
        id: albaran,
        ejercicio: ejercicio,
        sucursal: sucursal
    }
    fetch(process.env.REACT_APP_WS, JSONRPC('albaranes.pdf', parametros))
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.status + ' ' + response.statusText);
            }
        })
        .then((rpc) => {
            // Descarga de un fichero
            var a = document.createElement("a")
            a.href = "data:application/pdf;base64," + rpc.result.contenidoFichero;
            a.download = rpc.result.nombreFichero
            a.click();
        })
        .catch(err => console.log(err.message));
}


/**
 * Descarfar una factura en PDF
 * @param {string} albaran Número de albarán
 * @param {string} sucursal Código de sucursal
 * @param {number} ejercicio Ejercicio o año
 */
export function descargarFacturaPDF(factura, sucursal, ejercicio) {
    var parametros = {
        id: factura,
        ejercicio: ejercicio,
        sucursal: sucursal
    }
    fetch(process.env.REACT_APP_WS, JSONRPC('facturas.pdf', parametros))
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.status + ' ' + response.statusText);
            }
        })
        .then((rpc) => {
            // Descarga de un fichero
            var a = document.createElement("a")
            a.href = "data:application/pdf;base64," + rpc.result.contenidoFichero;
            a.download = rpc.result.nombreFichero
            a.click();
        })
        .catch(err => console.log(err.message));
}


/**
 * Generar y descargar un fichero CSV a partir de un array
 * @param {array} myJsonDataArray 
 * @returns 
 */
export function descargarCSV(myJsonDataArray) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(myJsonDataArray);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}


function convertArrayOfObjectsToCSV(data) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            // eslint-disable-next-line no-plusplus
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}


