import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Banner de cookies
 * @returns 
 */
export default function BannerCookies() {

    return (
        <div className="alert alert-dismissible fade show bg-dark bg-opacity-90 text-white position-fixed rounded-0 m-0 d-md-flex align-items-center px-xl-5 py-xl-4 pt-4 start-0 end-0 bottom-0" style={{ zIndex: 1020 }}>
            <div className="flex-fill">
                <h3>Este sitio usa cookies</h3>
                <p className="mb-0">
                    Usamos cookies en nuestra página web para ver cómo interactúas con ella y mejorar su funcionalidad y nuestros servicios. Al aceptarlas, estás de acuerdo con nuestro uso de dichas cookies.
                </p>
                <Link to="/legal/cookies">Política de cookies</Link>
            </div>
            <div className="ps-xl-4 pt-xl-0 pt-3">
                <button className="btn btn-outline-primary btn-lg w-150px" data-bs-dismiss="alert">Aceptar</button>
            </div>
        </div>

    )
}